import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Network } from '@capacitor/network'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { IonImg, IonThumbnail, IonSkeletonText } from '@ionic/react'
// firebase storage
import { storage } from '../../firebase/config'
// import { Errors } from '../../firebase/error-codes'
import { ref as storageRef, getDownloadURL } from 'firebase/storage'
// import { useDownloadURL } from 'react-firebase-hooks/storage'
// hooks
import { useImage } from '../../contexts/ImageContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { OfflineAlert } from '../../components'
import { useAlert } from '../../contexts/AlertContext'
interface ThumbnailProps {
  bid: string
  downloadUrl: string
  isCover?: boolean
  withShadow?: boolean
  onClick?: () => void
}

function Thumbnail({ bid, downloadUrl, isCover, withShadow, onClick }: ThumbnailProps) {
  const { connected } = useNetwork()
  const { setAlert } = useAlert()
  // const [url, imgLoading, error] = useDownloadURL(storageRef(storage, downloadUrl))

  // updateMetadata(storageRef(storage, downloadUrl), {
  //   cacheControl: 'public,max-age=3600'
  // })

  const { image, setImage, imgLoading, setImgLoading } = useImage()

  // const [src, setSrc] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  // const [imgLoading, setImgLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!image[bid]) getURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid])

  useEffect(() => {
    // setAlert(!connected)
    console.log('no internet connection')
  }, [connected, setAlert])

  const base64FromBlob = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject('method did not return a string')
        }
      }
      reader.readAsDataURL(blob)
    })
  }

  const getURL = () => {
    setImgLoading(bid, true)
    // get download url from firebase storage if network is available and image is not cached in filesystem else get from filesystem
    Network.getStatus().then((status) => {
      if (status.connected) {
        // before getting image from firebase storage check if image is cached in filesystem
        Filesystem.readFile({
          path: bid,
          directory: Directory.Cache
        })
          // if image is cached in filesystem then get image from cache otherwise get image from firebase storage
          .then((result) => {
            const webviewPath = `data:image/jpeg;base64,${result.data}`
            // get image path to display
            setImage(bid, webviewPath)
          })
          .catch((error) => {
            getDownloadURL(storageRef(storage, downloadUrl))
              .then(async (url) => {
                // setSrc(url)
                setImage(bid, url)
                // create image file to save in filesystem
                const blob = await fetch(url).then((r) => r.blob())
                const base64Data = await base64FromBlob(blob)
                Filesystem.writeFile({
                  path: bid,
                  data: base64Data,
                  directory: Directory.Cache
                })
                  .then((result) => {
                    // console.log('result', result)
                  })
                  .catch((error) => {
                    console.log('Thumbnail error 1', error)
                  })
              })
              .catch((error) => {
                console.log('Thumbnail error 2', error)
              })
              .finally(() => {
                setImgLoading(bid, false)
              })
          })
          .finally(() => {
            setImgLoading(bid, false)
          })
      } else {
        Filesystem.readFile({
          path: bid,
          directory: Directory.Cache
        })
          .then((result) => {
            // setSrc(result.data)
            const webviewPath = `data:image/jpeg;base64,${result.data}`
            // get image path to display
            setImage(bid, webviewPath)
          })
          .catch((error) => {
            console.log('Thumbnail error 3', error, bid)
          })
          .finally(() => {
            setImgLoading(bid, false)
          })
      }
    })
  }

  if (!bid) return null
  
  return (
    <div
      className={twMerge(
        'h-[140px] sm:h-[210px] w-[100px] sm:w-[135px] rounded-md bg-[#edeff3]',
        isCover ? 'w-[200px] h-[290px] sm:w-[250px] sm:h-[350px]' : 'sm:mb-4',
        withShadow && 'shadow-xl'
      )}
    >
      {(imgLoading[bid] || loading) && (
        <IonThumbnail className="w-full h-full rounded-md">
          <IonSkeletonText animated style={{ '--border-radius': '0.357rem' }} />
        </IonThumbnail>
      )}
      <IonImg
        // src={src}
        id={bid}
        src={connected || image[bid] ? image[bid] || 'assets/cover.jpg' : 'assets/cover.jpg'}
        alt="book cover"
        className={twMerge(
          'w-[100px] sm:w-[135px] h-[140px] sm:h-[210px] object-cover shadow-lg rounded-md transition-opacity duration-500',
          imgLoading[bid] || loading ? 'opacity-0 invisible' : 'opacity-100',
          isCover ? 'w-[200px] h-[290px] sm:w-[250px] sm:h-[350px]' : 'hover:cursor-pointer'
        )}
        onIonImgWillLoad={() => {
          setLoading(true)
          // console.log('image will load')
        }}
        onIonImgDidLoad={() => {
          // setSrc(
          //   "https://images-na.ssl-images-amazon.com/images/I/51ZU%2BCvkTyL._SX331_BO1,204,203,200_.jpg"
          // )
          setLoading(false)
          // console.log('image loaded')
        }}
        onIonError={(error) => {
          // show error image
          console.log('image error', error)
          // set src to default image
          document.getElementById(bid)?.setAttribute('src', 'assets/cover.jpg')
          // setSrc('/assets/cover.jpg')
          // console.log('error loading image', error)
        }}
        onClick={onClick}
      />
      <OfflineAlert />
    </div>
  )
}

export default Thumbnail
