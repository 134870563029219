import { FC, useEffect, useState } from 'react'
import { NotFound } from '../index'
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonList,
  IonIcon,
  IonButton,
  IonLabel,
  IonSpinner
} from '@ionic/react'
import { Filesystem, Directory } from '@capacitor/filesystem'
// hooks
import useAuth from '../../hooks/useAuth'
import { useGetUsersBooks } from '../../hooks/useUserQueries'

const Records: FC = () => {
  const [records, setRecords] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useAuth()
  const { data: books } = useGetUsersBooks(user?.uid)

  const getRecords = async () => {
    setLoading(true)
    const bookList = books?.map((book) => ({ bid: book.folderName, title: book.title })) as any[]
    const dir = await Filesystem.readdir({
      path: '',
      directory: Directory.Data
    })
    const list = [] as any[]
    const final = dir.files.map(async (directory) => {
      if (directory.type === 'directory') {
        const dirFiles = await Filesystem.readdir({
          path: directory.name,
          directory: Directory.Data
        })

        const result = dirFiles.files.map(async (file) => {
          if (file.type === 'directory' && file.name === 'record') {
            const recordDir = await Filesystem.readdir({
              path: file.uri
            })
            recordDir.files.map((record) => {
              if (record.type === 'file') {
                const BookOfRecord = bookList.find((book) => record.uri.includes(book.bid))
                const pageNumber = Number(record.name.split('.')[0])
                if (BookOfRecord) list.push({ ...BookOfRecord, pageNumber, recordPath: record.uri })
              }
              return null
            })
          }
        })
        await Promise.all(result)
      }
    })
    await Promise.all(final)
    list.sort((a: any, b: any) => a.title.localeCompare(b.title))
    setRecords(list)
    setLoading(false)
  }

  useEffect(() => {
    if (!books) return
    getRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [books])

  const handleDelete = async (recordPath: string) => {
    try {
      await Filesystem.deleteFile({
        path: recordPath
      })
      getRecords()
    } catch (error) {
      console.log('error in handleDelete', error)
    }
  }

  return records.length > 0 ? (
    <IonCard className="rounded-xl max-w-4xl mx-auto">
      <IonCardTitle className="font-bold pt-4 pr-4">تسجيلاتي</IonCardTitle>
      <IonCardContent>
        <IonList lines="full">
          {records.map(({ bid, pageNumber, readCount, title, recordPath }) => (
            <div key={`${recordPath}`} className="flex flex-row items-center justify-between">
              <IonItem className="w-full" routerLink={`/story/${bid}/${pageNumber}/${readCount}/true`}>
                <IonIcon icon="/assets/icon/ic_mic_f.svg" slot="start" />
                <IonLabel className="ion-text-wrap">
                  <span className="font-29LTKaff">{title}</span>
                </IonLabel>
                <IonLabel slot="end">صفحة {pageNumber}</IonLabel>
              </IonItem>
              <IonButton slot="end" color="danger" fill="clear" size="small" onClick={() => handleDelete(recordPath)}>
                <IonIcon slot="icon-only" aria-hidden="true" icon={'/assets/icon/ic_delete.svg'} />
              </IonButton>
            </div>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : loading ? (
    <IonSpinner className="w-full mx-auto" />
  ) : (
    <NotFound title="مفكّرتك خالية" subtitle="احفظ تسجيلاتك في المفكرة" img="/assets/illustrations/empty_records.png" />
  )
}

export default Records
