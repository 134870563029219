import { useRef, useState } from 'react'
import { closeOutline } from 'ionicons/icons'
import { RouteComponentProps } from 'react-router'
import { chevronDown, chevronUp, arrowBack } from 'ionicons/icons'
import {
  IonImg,
  IonContent,
  useIonViewWillEnter,
  IonFooter,
  IonPage,
  IonButton,
  IonToolbar,
  IonIcon,
  IonText,
  IonLoading,
  IonHeader,
  IonButtons,
  useIonRouter,
  IonCard,
  IonRow
} from '@ionic/react'
import { Browser } from '@capacitor/browser'
import { useEditUserData } from '../../hooks/useUser'
import { useNewSubscription, useNewInAppSubscription } from '../../services/subscription'
import useAuth from '../../hooks/useAuth'
import { Preferences } from '@capacitor/preferences'
import { isPlatform } from '@ionic/react'
import { updateUserSubscription } from '../../api'
import { format } from 'date-fns'

export const isUserSubscribed = async (user: any) => {
  const waitDuration = 60
  const monthMS = 31 * 24 * 60 * 60 * 1000
  let loopCounter = 0
  return new Promise((resolve) => {
    const waitForReceiptVerification = (counter: number) => {
      loopCounter = counter
      console.log('waitForReceiptVerification', loopCounter)
      setTimeout(async () => {
        if ((await Preferences.keys()).keys.includes('process_failed')) {
          Preferences.remove({ key: 'process_failed' })
          loopCounter = waitDuration
        } else if ((await Preferences.keys()).keys.includes('receipt_verified')) {
          Preferences.remove({ key: 'receipt_verified' })
          loopCounter = waitDuration
          let purchase_date_ms = new Date().getTime()
          if ((await Preferences.keys()).keys.includes('purchase_date_ms')) {
            const ret = await Preferences.get({ key: 'purchase_date_ms' })
            purchase_date_ms = parseInt(ret.value as string)
            Preferences.remove({ key: 'purchase_date_ms' })
          }
          const purchasetime = purchase_date_ms
          const expirytime = purchase_date_ms + monthMS
          const subscription = {
            subscription: {
              isSubscribed: true,
              expiryDate: format(new Date(expirytime), 'yyyy-MM-dd'),
              expiryTime: expirytime,
              purchaseDate: format(new Date(purchasetime), 'yyyy-MM-dd'),
              purchaseTime: purchasetime,
              type: isPlatform('android') ? 'playStore' : 'appStore'
            }
          }
          return resolve(subscription)
        }
        if (loopCounter < waitDuration) waitForReceiptVerification(loopCounter + 1)
        else {
          return resolve({ subscription: { isSubscribed: false } })
        }
      }, 1000)
    }
    waitForReceiptVerification(0)
    Preferences.set({ key: 'verify_Subscription_Receipt', value: 'true' })
  })
}

type Props = RouteComponentProps<{ subscribed: string }>

const Subscription: React.FC<Props> = ({ match }) => {
  const valid = true
  const waitDuration = 90
  const monthMS = 31 * 24 * 60 * 60 * 1000
  const router = useIonRouter()
  const loopCounter = useRef(0)
  const mpurchasing = useRef(false)
  const mrestoring = useRef(false)
  const { user } = useAuth()
  const [showAlert, setShowAlert] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [price, setPrice] = useState('9.99')
  const [renewDate, setRenewDate] = useState('...')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [restoring, setRestoring] = useState(false)
  const [purchasing, setPurchasing] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const { mutate: newSubscription } = useNewInAppSubscription(user.uid)

  useIonViewWillEnter(() => {
    initialize()
  })

  const initialize = async () => {
    if (!initialized) {
      console.log('intialized')
      setInitialized(true)
      setProductParams()
    }
  }

  const reset = async () => {
    console.log('reset', mpurchasing.current, mrestoring.current)
    setPurchasing(false)
    setRestoring(false)
    mrestoring.current = false
    mpurchasing.current = false
  }

  const setProductParams = async () => {
    if ((await Preferences.keys()).keys.includes('product_price')) {
      const { value } = await Preferences.get({ key: 'product_price' })
      setPrice(value as any)
      console.log('product_price:', value)
    }
    const date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    const day = date.getDate()
    if (month > 12) {
      month = 1
      year = year + 1
    }
    const newDate = day + '-' + month + '-' + year
    console.log('myProduct newDate:', newDate)
    setRenewDate(newDate)
  }

  const setUserIsSubscried = async (msg: string, isSubscribed: boolean) => {
    let purchase_date_ms = new Date().getTime()
    if ((await Preferences.keys()).keys.includes('purchase_date_ms')) {
      const ret = await Preferences.get({ key: 'purchase_date_ms' })
      purchase_date_ms = parseInt(ret.value as string)
      Preferences.remove({ key: 'purchase_date_ms' })
    }
    setSubscribed(isSubscribed)
    console.log('setUserIsSubscried', isSubscribed)
    const purchasetime = purchase_date_ms
    const expirytime = purchasetime + monthMS
    newSubscription({
      subscription: {
        isSubscribed: isSubscribed,
        expiryDate: format(new Date(expirytime), 'yyyy-MM-dd'),
        expiryTime: expirytime,
        purchaseDate: format(new Date(purchasetime), 'yyyy-MM-dd'),
        purchaseTime: purchasetime,
        type: isPlatform('android') ? 'playStore' : 'appStore'
      }
    })
    // await updateUserSubscription(user.uid, {
    //   subscription: {
    //     isSubscribed: isSubscribed,
    //     expiryDate: format(new Date(expirytime), 'yyyy-MM-dd'),
    //     expiryTime: expirytime,
    //     purchaseDate: format(new Date(purchasetime), 'yyyy-MM-dd'),
    //     purchaseTime: purchasetime,
    //     type: isPlatform('android') ? 'playStore' : 'appStore'
    //   }
    // })
    console.log('myProduct checkSubscription', isSubscribed)
    openAlert(msg)
    reset()
  }

  const waitForSubscription = async (counter: number) => {
    loopCounter.current = counter
    console.log('waitForSubscription', loopCounter.current)
    setTimeout(async () => {
      if ((await Preferences.keys()).keys.includes('purchase_in_Progress')) {
        Preferences.remove({ key: 'purchase_in_Progress' })
        loopCounter.current = -10
      } else if ((await Preferences.keys()).keys.includes('process_failed')) {
        console.log('process_failed', (await Preferences.get({ key: 'process_failed' })) as any)
        Preferences.remove({ key: 'process_failed' })
        reset()
        openAlert('للأسف فشلت عملية الإشتراك')
      } else if (
        (await Preferences.keys()).keys.includes('subscription_done') ||
        (await Preferences.keys()).keys.includes('restore_done')
      ) {
        console.log('subscription_done')
        Preferences.remove({ key: 'subscription_done' })
        setUserIsSubscried('تهانينا، لقد تمت عملية الإشتراك بنجاح', true)
      }
      if (loopCounter.current >= waitDuration) {
        reset()
        openAlert('للأسف فشلت عملية الإشتراك، حاول مرة أخرى')
      }
      if (mpurchasing.current && loopCounter.current < waitDuration) waitForSubscription(loopCounter.current + 1)
    }, 1000)
  }

  const startSubscription = async () => {
    console.log('start_subscription')
    setShowAlert(false)
    setPurchasing(true)
    mpurchasing.current = true
    waitForSubscription(0)
    await Preferences.set({ key: 'start_subscription', value: 'true' })
  }

  const restorePurchases = async () => {
    setShowAlert(false)
    setRestoring(true)
    mrestoring.current = true
    console.log('restorePurchases')
    waitForRestoreSubscription(0)
    await Preferences.set({ key: 'restore_subscription', value: 'true' })
  }

  const waitForRestoreSubscription = async (counter: number) => {
    loopCounter.current = counter
    console.log('waitForRestoreSubscription', loopCounter.current)
    setTimeout(async () => {
      if ((await Preferences.keys()).keys.includes('process_failed')) {
        console.log('process_failed', (await Preferences.get({ key: 'process_failed' })) as any)
        Preferences.remove({ key: 'process_failed' })
        loopCounter.current = waitDuration
      } else if ((await Preferences.keys()).keys.includes('restore_done')) {
        console.log('restore_done')
        Preferences.remove({ key: 'restore_done' })
        setUserIsSubscried('تهانينا، لقد تمت إستعادة الإشتراك بنجاح', true)
      }
      if (loopCounter.current >= waitDuration) {
        reset()
        openAlert('للأسف لم يتم العثور على إشتراك')
      }
      if (mrestoring.current && loopCounter.current < waitDuration) waitForRestoreSubscription(loopCounter.current + 1)
    }, 1000)
  }

  const onBackClicked = async () => {
    reset()
    console.log('onBackClicked purchasiung restoring', purchasing, mpurchasing.current, restoring, mrestoring.current)
    router.goBack()
  }

  const closeAlertMessage = () => {
    console.log('closeAlertMessage')
    setShowAlert(false)
    if (subscribed) {
      window.history.back()
    }
  }

  const openAlert = (message: string) => {
    setAlertTitle('اشتراك')
    setAlertMessage(message)
    setShowAlert(true)
    setShowDetails(false)
  }

  const showAlertMessage = () => {
    return (
      <div
        style={{
          position: 'absolute',
          textAlign: 'center',
          marginLeft: '25%',
          marginRight: '25%',
          width: '50%',
          display: showAlert ? '' : 'none'
        }}
      >
        <IonCard className="max-w-md p-4 flex flex-row mx-auto justify-between rounded-xl items-center">
          <div style={{ textAlign: 'center', width: '100%', color: 'black', fontSize: '4vw' }}>
            <div
              style={{ textAlign: 'left', marginRight: '0px' }}
              onClick={() => {
                closeAlertMessage()
              }}
            >
              <IonIcon icon={closeOutline} size="large" />
            </div>
            <h1 style={{ marginRight: '0px' }}>
              {' '}
              <strong>{alertTitle}</strong>{' '}
            </h1>{' '}
            <p />
            <h3> {alertMessage} </h3> <br />
          </div>
        </IonCard>
      </div>
    )
  }

  const privacyPolicy = async () => {
    await Browser.open({ url: 'https://www.digital-future.ca/privacy_policy/ios/Qissasi-Privacy.html' })
  }

  const termsAndConditoins = async () => {
    await Browser.open({ url: 'https://www.digital-future.ca/privacy_policy/ios/Qissasi-Terms.html' })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={'story'}>
          <IonButtons slot="end">
            <IonButton onClick={onBackClicked}>
              {' '}
              <IonIcon slot="start" icon={arrowBack} size="large" />{' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {showAlertMessage()}
        <IonImg style={{ height: '15%', marginBottom: '30px', marginTop: '20px' }} alt="Story Image" src={'icon.png'} />
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <IonText style={{ fontSize: '25px', fontWeight: 'bold' }}> مكتبتك للقصص التفاعلية </IonText>
          <p />
        </div>
        <p />

        <IonLoading
          dir="rtl"
          spinner={'lines'}
          isOpen={mpurchasing.current || mrestoring.current}
          message={'الرجاء الإنتظار...'}
        />

        <div
          style={{
            padding: '10px',
            width: '75%',
            marginLeft: '12.5%',
            marginRight: '12.5%',
            borderRadius: '10px',
            boxShadow: '2px 2px 5px 5px #f0f0f0'
          }}
        >
          <IonRow>
            <IonImg style={{ width: '5vw', height: '5vw' }} alt="Story Image" src={'group1.png'} />
            <span style={{ fontSize: '3vw', marginRight: '5px' }}>العديد من القصص في متناول يديك</span>
          </IonRow>
          <br />
          <IonRow>
            <IonImg style={{ width: '5vw', height: '5vw' }} alt="Story Image" src={'group2.png'} />
            <span style={{ fontSize: '3vw', marginRight: '5px' }}>إستخدام كامل لمكتبتي دون الإتصال بالإنترنت</span>
          </IonRow>
          <br />
          <IonRow>
            <IonImg style={{ width: '5vw', height: '5vw' }} alt="Story Image" src={'group3.png'} />
            <span style={{ fontSize: '3vw', marginRight: '5px' }}>مناسب لجميع الأعمار وخاصة للأطفال دون 15 سنة</span>
          </IonRow>
        </div>
        <br />

        <div style={{ textAlign: 'center' }}>
          <IonButton
            style={{ minimumWidth: '30%', fontSize: '3vw', height: '56px' }}
            onClick={() => {
              if (valid) setShowDetails(!showDetails)
              else {
                setAlertTitle('اشتراك')
                setAlertMessage('للأسف لم يتم العثور على الإشتراك')
                setShowAlert(true)
              }
            }}
          >
            اشتراك شهري بـ {price} دولار أمريكي
            <IonIcon size="small" slot="start" icon={showDetails ? chevronUp : chevronDown} />
          </IonButton>
          <div style={{ display: showDetails ? '' : 'none', margin: '10px' }}>
            <IonText style={{ fontSize: '5vw', fontWeight: '' }}>لقد اخترت الاشتراك بـ {price} شهرياً </IonText>
            <br />
            <IonText style={{ fontSize: '5vw', fontWeight: '' }}>الاشتراك يتجدد شهرياً ابتداءً من {renewDate} </IonText>
            <br />
            <IonText style={{ fontSize: '5vw', fontWeight: '' }}>
              بالإمكان إيقاف التجديد التلقائي بالانتقال إلى إعدادات حساب المستخدم بعد الإشتراك{' '}
            </IonText>
            <br />
            <IonButton onClick={startSubscription} style={{ fontSize: '3vw', height: '56px', width: '35%' }}>
              {' '}
              إشترك الآن{' '}
            </IonButton>
          </div>
        </div>
        <br />
        <div style={{ textAlign: 'center' }}>
          <IonText style={{ fontSize: '4vw' }}>لديك اشتراك سابق؟</IonText>
          <br />
          <IonButton
            color="story"
            disabled={false}
            onClick={restorePurchases}
            style={{ fontSize: '3vw', height: '56px', width: '35%' }}
          >
            إستعادة المشتريات
          </IonButton>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton fill="clear" onClick={privacyPolicy} slot="start">
            سياسة الخصوصية
          </IonButton>
          <IonButton fill="clear" onClick={termsAndConditoins} slot="end">
            الشروط والأحكام
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}
export default Subscription
