import { createContext, useReducer } from 'react'

const initialState = {
  show: false,
  bid: ''
}

type State = {
  show: boolean
  bid: string
}

const StateContext = createContext({} as { state: State; dispatch: any })

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        show: true,
        bid: action.payload
      }
    case 'HIDE':
      return {
        ...state,
        show: false,
        bid: ''
      }
    default:
      return state
  }
}

const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>
}

const StateConsumer = StateContext.Consumer

export { StateContext, StateProvider, StateConsumer }
