import React, { useState } from 'react'
import { IonProgressBar, IonContent, IonButton, useIonToast } from '@ionic/react'
// firebase storage
import { storage } from '../../firebase/config'
import { Errors } from '../../firebase/error-codes'
import { ref as storageRef } from 'firebase/storage'
import { useDownloadURL } from 'react-firebase-hooks/storage'
//
import JSZip from 'jszip'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

const DownloadProgress: React.FC = () => {
  const [url, isLoading, error] = useDownloadURL(storageRef(storage, `stories/Level1/big_carrot/big_carrot.zip`))

  const [progress, setProgress] = useState<number>(0)
  // const [extractProgress, setExtractProgress] = useState<number>(0)

  // const [isDownloading, setIsDownloading] = useState(false)

  const [present] = useIonToast()

  if (error) {
    console.log('error', error)
    present({
      message: Errors[error.code] ? Errors[error.code] : 'حدث خطأ في تحميل القصة',
      duration: 3000,
      color: 'danger'
    })
  }

  const downlaodFile = async () => {
    try {
      const response = await fetch(url as string)
      console.log('response', response)
      const data = await response.blob()
      /*
      using chatgpt to get progress value
      */
      const reader = new FileReader()
      reader.onloadend = () => {
        console.log('Download complete')
      }
      reader.onprogress = (event) => {
        const newProgress = Math.round((event.loaded / event.total) * 100)
        console.log('newProgress', newProgress)
        setProgress(newProgress)
      }
      reader.readAsArrayBuffer(data)

      const zip = new JSZip()
      const zipData = await zip.loadAsync(data)
      await Promise.all(
        Object.values(zipData.files).map(async (file) => {
          // console.log('file', file)
          if (file.dir) {
            // const isFolderExists = await Filesystem.stat({
            //   path: file.name,
            //   directory: Directory.Data
            // })
            // if (!isFolderExists) {
            try {
              await Filesystem.mkdir({
                path: file.name,
                directory: Directory.Data,
                recursive: true
              })
            } catch (error) {
              console.log('error', error)
            }
            // }
          } else {
            const base64Data = await file.async('base64')
            await file.async('base64', (metadata) => {
              const progress = Math.floor(metadata.percent)
              console.log(`Extraction progress: ${progress}%`)
            })
            const savedFile = await Filesystem.writeFile({
              path: file.name,
              data: base64Data,
              directory: Directory.Data,
              encoding: Encoding.UTF8
            })
            console.log('savedFile', savedFile)
          }
        })
      )
      console.log('ZIP file downloaded and extracted successfully!')
    } catch (error) {
      console.error('Error downloading and extracting ZIP file:', error)
    }
  }

  return (
    <IonContent>
      <div className="progress-container">
        <IonProgressBar value={progress} buffer={100}></IonProgressBar>
        <div className="progress-label text-3xl text-red-700 text-center">{progress}%</div>
      </div>
      <hr />
      {!isLoading && url && <IonButton onClick={downlaodFile}>Download Zip</IonButton>}
    </IonContent>
  )
}

export default DownloadProgress
