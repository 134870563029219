import { FC } from 'react'
import { IonPage, IonImg } from '@ionic/react'

const Loader: FC = () => {
  return (
    <IonPage className="flex justify-center items-center h-full bg-white z-50">
      <IonImg src="assets/loader.gif" className="logo" />
    </IonPage>
  )
}

export default Loader
