import { FC, useState, useEffect } from 'react'
import { IonList, IonItem, IonText, isPlatform, IonButton } from '@ionic/react'
import { Preferences } from '@capacitor/preferences'

import useAuth from '../../hooks/useAuth'
import { useGetUserData } from '../../hooks/useUser'
// tools
import { formatDate } from '../../utils/formatDate'

const SubscriptionInfo: FC = () => {
  const { user } = useAuth()
  const { data: userData } = useGetUserData(user.uid)
  const [expiryDate, setExpiryDate] = useState<any>('')

  const IN_APP_PURCHASE = userData?.subscription?.type !== 'promo'

  useEffect(() => {
    try {
      if (userData?.subscription.isSubscribed) {
        const t = new Date(userData.subscription.expiryTime)
        setExpiryDate(formatDate(t))
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [userData])

  const handleSubscription = () => {
    console.log('handleSubscription')
    if (isPlatform('hybrid')) {
      Preferences.set({ key: 'manage_subscription', value: 'true' })
    }
  }

  return (
    <fieldset className="border-tertiary-main border-2 rounded-xl my-8 w-full">
      <legend
        className="text-tertiary-main text-right mr-4 px-1 text-xl font-bold"
        color="inherit"
        style={{ color: '#1b85b6' }}
      >
        الإشتراك
      </legend>
      <IonList className="rounded-xl" lines="full">
        <IonItem>
          <IonText>
            لديك إشتراك صالح حتى: <span className="text-tertiary-main font-bold">{expiryDate}</span>
          </IonText>
        </IonItem>
        {isPlatform('hybrid') && IN_APP_PURCHASE && (
          <>
            <IonItem>
              <IonText>
                <span className="text-secondary-main font-bold">تنويه:</span> يتم تجديد الإشتراك تلقائياً عند انتهاء
                مدته، يمكنك إلغاء الإشتراك من الإعدادات
              </IonText>
            </IonItem>
            <IonItem>
              <IonButton color={'light'} slot="end">
                <IonText color={'secondary'} className="font-bold" onClick={handleSubscription}>
                  إعدادات الإشتراك
                </IonText>
              </IonButton>
            </IonItem>
          </>
        )}
      </IonList>
    </fieldset>
  )
}

export default SubscriptionInfo
