import { FC, useState, useRef } from 'react'
import useAuth from '../../hooks/useAuth'
import { twMerge } from 'tailwind-merge'
import { NotFound } from '../../components'
import { DatetimeHighlight } from '@ionic/core'
import useModalState from '../../hooks/useState'
import { IonDatetime, IonRow, IonCol, IonButton, IonCardContent, IonImg, IonLabel, IonLoading } from '@ionic/react'
import { IonContent, IonHeader, IonPage, IonToolbar, IonIcon, isPlatform, IonCard, IonCheckbox } from '@ionic/react'
import { useIonViewWillLeave, useIonViewWillEnter } from '@ionic/react'
import { getTasksProgress, updateTaskProgress } from '../../api'

const UserTasks: FC = () => {
  const { dispatch } = useModalState()
  const enum displayPlanTypes {
    TASKS = 0,
    GOALS,
    BOOKS
  }
  const { user } = useAuth()
  const uid = user?.uid
  const name = user?.name
  const responseCounter = useRef(0)
  const [tasks, setTasks] = useState<any>([])
  const [plans, setPlans] = useState<any>([])
  const [showPicker, setShowPicker] = useState(false)
  const [planIndex, setPlanIndex] = useState<number>(0)
  const [showLoading, setShowLoading] = useState(true)
  const [hasTasks, setHasTasks] = useState<boolean>(false)
  const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0])
  const [highlightedDates, setHighlightedDates] = useState<DatetimeHighlight[]>([])
  const [displayPlanType, setDisplayPlanType] = useState<displayPlanTypes>(displayPlanTypes.TASKS)
  const monthNames = [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر'
  ]

  useIonViewWillLeave(() => {
    setShowPicker(false)
  }, [])

  const startRestoreTasksTimeOut = async (counter: number) => {
    responseCounter.current = counter
    setTimeout(async () => {
      if (responseCounter.current < 60 && plans.length === 0) {
        startRestoreTasksTimeOut(responseCounter.current + 1)
      } else {
        setShowLoading(false)
      }
    }, 1000)
  }

  useIonViewWillEnter(async () => {
    startRestoreTasksTimeOut(0)
    const plans = await getTasksProgress(uid)
    setPlans(plans)
    setPlanIndex(plans.length -1)
    setShowLoading(false)
    console.log('UserTasks.plans: ', plans)
    if (plans.length === 0) {
      setHasTasks(false)
    } else {
      setPlanDays(plans[plans.length - 1])
    }
    setAllPlansDays(plans)
  }, [])

  const setAllPlansDays = (plans: any) => {
    const highlighted = [{ date: '', backgroundColor: '' }] as DatetimeHighlight[]
    plans.map((plan: any) => {
      plan.tasks.map((t: any, index: number) => {
        if (!t.task_date) return <></>
        else {
          return highlighted.push({
            date: t.task_date.split('T')[0],
            backgroundColor: '#c8e5d0',
            textColor: t.task_date.split('T')[0] === date ? '#ffffff' : '#000000'
          })
        }
      })
      return <></>
    })
    console.log('setAllPlansDays.highlighted: ', highlighted)      
    setHighlightedDates(highlighted)
  }

  const setPlanDays = (plan: any) => {    
    setTasks(plan.tasks)
    console.log('setPlanDays: ', plan.tasks.length > 0)
    setHasTasks(plan.tasks.length > 0)
    // if (plan.tasks.length > 0) {
    //   const highlighted = [{ date: '', backgroundColor: '' }] as DatetimeHighlight[]
    //   plan.tasks.map((t: any, index: number) => {
    //     if (!t.task_date) return <></>
    //     else {
    //       return highlighted.push({
    //         date: t.task_date.split('T')[0],
    //         backgroundColor: '#c8e5d0',
    //         textColor: t.task_date.split('T')[0] === date ? '#ffffff' : '#000000'
    //       })
    //     }
    //   })
    //   //setHighlightedDates(highlighted)
    // }
      setDate(plan.tasks[0].task_date.split('T')[0])
      formateDate(plan.tasks[0].task_date.split('T')[0])
      console.log('highlightedDates: ', plan.tasks[0].task_date.split('T')[0])
      setShowPicker(false)
  }

  const saveTask = async (index: number) => {
    const task = plans[planIndex].tasks[index]
    const response = await updateTaskProgress(uid, task.task_id, { done: task.done })
    console.log('saveTasks: ', response)
  }

  const formateDate = (date: string) => {
    const d = new Date(date.split('T')[0])
    return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear()
  }

  const setPlanIndexFromDate = (date: string) => {
    plans.map((plan: any, index: number) => {
      plan.tasks.map((t: any) => {
        if (t.task_date.split('T')[0] === date) {
          setPlanIndex(index)
          setTasks(plan.tasks)
        }
        return <></>
      })
      return <></>
    })
  }

  const getPickerValue = (e: any) => {
    const date = e.target.value.split('T')[0]
    setDate(date)
    formateDate(date)
    setPlanIndexFromDate(date)
  }

  const dateTimePicker = () => {
    if (!hasTasks) return <></>
    else
      return (
        <div className="flex flex-col justify-center items-center min-h-[300px] mx-2">
          <IonCard className="rounded-xl flex flex-col justify-between items-center py-6 gap-y-4 w-full max-w-xl">
            <div className="flex flex-col justify-center items-center gap-4">
              <IonImg src={'/assets/icon/calendar.png'} className="w-[150px] mt-4" style={{ height: '64px' }} />
            </div>
            <IonButton onClick={() => setShowPicker(!showPicker)}>
              {!showPicker ? 'إختر تاريخ المهام' : 'موافق'}
            </IonButton>
            <IonCardContent>
              {showPicker ? (
                <IonDatetime 
                  value={date}
                  highlightedDates={highlightedDates}
                  presentation="date"
                  preferWheel={false}
                  onIonChange={(e) => getPickerValue(e)}
                />
              ) : (
                <>{<span style={{ fontSize: '18px' }}>{formateDate(date)}</span>}</>
              )}
            </IonCardContent>
          </IonCard>
        </div>
      )
  }

  const onTaskCheckChange = (t: any) => {
    const itemID = parseInt(t.target.id)
    const taskIndex = itemID < 2000 ? itemID - 1000 : itemID - 2000
    const titleID = (2000 + taskIndex).toString()
    const checkID = (1000 + taskIndex).toString()
    const check = document.getElementById(checkID) as HTMLIonCheckboxElement
    const titleDiv = document.getElementById(titleID) as HTMLDivElement
    if (parseInt(checkID) !== itemID) {
      check.checked = !check.checked
    }
    plans[planIndex].tasks[taskIndex - 1].done = check.checked ? 1 : 0
    titleDiv.style.textDecoration = check.checked ? 'line-through' : ''
    saveTask(taskIndex - 1)
  }

  const onPlanClick = (index: number) => {
    console.log('onCardClick')
    if (index !== planIndex) {
      setPlanIndex(index === planIndex ? -1 : index)
      setPlanDays(plans[index])
    } else {
      setPlanIndex(-1)
    }
  }

  const renderTask = (t: any, index: number, pIndex: number) => {
    if (t.task_date && t.task_date.split('T')[0] === date)
      return (
        <div id={(index + 3000).toString()}>
          <table>
            <tbody>
              <tr>
                <td>
                  <IonCheckbox
                    id={(index + 1000).toString()}
                    dir="ltr"
                    checked={t.done === 1 ? true : false}
                    className="ion-text-wrap"
                    style={{ marginBottom: '0px', marginRight: '25px', fontSize: '1.75vh' }}
                    onIonChange={(t) => onTaskCheckChange(t)}
                  ></IonCheckbox>
                </td>
                <td>
                  <div
                    id={(index + 2000).toString()}
                    onClick={(t) => onTaskCheckChange(t)}
                    style={{ textDecoration: t.done === 1 ? 'line-through' : '', marginRight: '5px', marginTop: '-5px', color: 'black', fontSize: '1.75vmax', wordWrap: 'break-word'}}
                  >
                    {t.task_description}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    else return <></>
  }

  const renderTasks = (pIndex: number) => {
    if (pIndex !== planIndex) return <></>
    else
      return tasks.map((t: any, index: number) => {
        return <div key={index}>{renderTask(t, index + 1, pIndex)}</div>
      })
  }

  const renderGoals = (pIndex: number) => {
    if (pIndex !== planIndex) return <></>
    else
      return plans[planIndex].goals.map((t: any, index: number) => {
        return (
          <div key={index}>
            <ul style={{ listStyleType: 'disc', textAlign: 'right', marginRight: '25px' }}>
              <li style={{ color: '#ad1deb', marginRight: '20px' }}>
                <span style={{ color: 'black' }} className="text-lg md:text-2xl">
                  {t}
                </span>
              </li>
            </ul>
          </div>
        )
      })
  }

  const gotoBookDetails = (bid: any) => {
    console.log('gotoBookDetails.bookId: ', bid)
    dispatch({ type: 'SHOW', payload: bid })
  }

  const renderBooks = (pIndex: number) => {
    console.log('renderBooks: ', planIndex)
    if (
      planIndex === -1 ||
      pIndex !== planIndex ||
      plans[planIndex].books === undefined ||
      !plans[planIndex].books.title
    )
      return <></>
    else
      return plans[planIndex].books.title.map((t: any, index: number) => {
        return (
          <div key={index}>
            <ul style={{ listStyleType: 'disc', textAlign: 'right', marginRight: '25px', fontSize: '1.75vh' }}>
              <li
                style={{ color: '#ad1deb', marginRight: '20px', display: t.length > 0 ? '' : 'none' }}
                onClick={() => gotoBookDetails(plans[planIndex].books.bid[index])}
              >
                <IonButton
                  size="large"
                  className="btn btn-default btn-sm"
                  style={{ width: isPlatform('hybrid') ? '50%' : '30%', color: 'white', whiteSpace: 'normal', fontSize: '1.75vh' }}
                >
                  {t}
                </IonButton>
              </li>
            </ul>
          </div>
        )
      })
  }

  const displayTasks = (plan: any, index: number) => {
    if (planIndex === -1) return <></>
    else
      return (
        <>
          <hr style={{ display: index === planIndex ? '' : 'none' }} />
          <div
            className="flex flex-col justify-center items-center gap-4"
            style={{ display: index === planIndex ? '' : 'none' }}
          >
            <IonRow style={{ width: '100%', margin: '20px' }}>
              <IonCol style={{ textAlign: 'center' }}>
                <IonIcon
                  icon={ displayPlanType === displayPlanTypes.TASKS ? '/assets/icon/nav_tasks_f.svg' : '/assets/icon/nav_tasks_o.svg' }
                  className="h-6 w-6"
                  onClick={() => setDisplayPlanType(displayPlanTypes.TASKS)}
                />
                <br />
                <IonLabel
                  className="ion-text-wrap"
                  style={{ color: '#ad1deb', fontWeight: 'bold', fontSize: '1.25vmax', display: displayPlanType === displayPlanTypes.TASKS ? '' : 'none' }}
                >
                  المهام
                </IonLabel>
              </IonCol>
              <IonCol style={{ textAlign: 'center' }}>
                <IonIcon
                  icon={ displayPlanType === displayPlanTypes.GOALS ? '/assets/icon/nav_objectives_f.svg' : '/assets/icon/nav_objectives_o.svg' }
                  className="h-6 w-6"
                  onClick={() => setDisplayPlanType(displayPlanTypes.GOALS)}
                />
                <br />
                <IonLabel
                  className="ion-text-wrap"
                  style={{
                    color: '#ad1deb',
                    fontWeight: 'bold',
                    fontSize: '1.25vmax',
                    display: displayPlanType === displayPlanTypes.GOALS ? '' : 'none'
                  }}
                >
                  الأهداف
                </IonLabel>
              </IonCol>
              <IonCol style={{ textAlign: 'center' }}>
                <IonIcon
                  icon={
                    displayPlanType === displayPlanTypes.BOOKS
                      ? '/assets/icon/nav_book_f.svg'
                      : '/assets/icon/nav_book_o.svg'
                  }
                  className="h-6 w-6"
                  onClick={() => setDisplayPlanType(displayPlanTypes.BOOKS)}
                />
                <br />
                <IonLabel
                  className="ion-text-wrap"
                  style={{ color: '#ad1deb', fontWeight: 'bold', fontSize: '1.25vmax', display: displayPlanType === displayPlanTypes.BOOKS ? '' : 'none' }}
                >
                  الكتب
                </IonLabel>
              </IonCol>
            </IonRow>
          </div>
          {displayPlanType === displayPlanTypes.TASKS ? renderTasks(index) : displayPlanType === displayPlanTypes.GOALS ? renderGoals(index) : renderBooks(index)}
        </>
      )
  }
  const renderPlans = () => {
    if (plans.length === 0)
      return <NotFound title="لائحة المهام خالية" subtitle="لا يوجد مهام" img="/assets/icon/tasks.png" />
    else
      return plans.map((plan: any, index: number) => {
        return (
          <IonCard key={'IonCard' + index} id="{index}" className="rounded-xl shadow-xl container mx-auto" style={{ border: 'solid gray 1px', width: '75%' }}>
            <div className="flex flex-col justify-center items-center gap-4" onClick={() => { onPlanClick(index) }}>
              <IonRow key={'row1' + index} style={{ width: '100%', margin: '0px' }}>
                <IonCol key={'col1-1' + index} style={{}}>
                  <strong>
                    <IonLabel className="ion-text-wrap" style={{ fontSize: '1.5.0vmax' }}>
                      {plan.title}
                    </IonLabel>
                  </strong>
                </IonCol>
                <IonCol key={'col1-2' + index} style={{ textAlign: 'left', fontSize: '1.5.0vmax' }}>
                  <strong>
                    <IonLabel className="ion-text-wrap"> &nbsp;من&nbsp; {formateDate(plan.start_date)}</IonLabel>
                  </strong>
                </IonCol>
              </IonRow>
              <IonRow key={'row2' + index} style={{ width: '100%', marginTop: '-20px' }}>
                <IonCol key={'col2-1' + index} style={{}}>
                  <strong>
                    <IonLabel className="ion-text-wrap" style={{ fontSize: '1.5.0vmax' }}>
                      الأستاذ : {plan.name}
                    </IonLabel>
                  </strong>
                </IonCol>
                <IonCol key={'col2-2' + index} style={{ textAlign: 'left', fontSize: '1.5.0vmax' }}>
                  <strong>
                    <IonLabel className="ion-text-wrap">&nbsp;إلى&nbsp;{formateDate(plan.end_date)}</IonLabel>
                  </strong>
                </IonCol>
              </IonRow>
            </div>
            {displayTasks(plan, index)}
            <br />
          </IonCard>
        )
      })
  }

  const Loader = () => {
    return (
      <IonLoading
        key="loader"
        dir="rtl"
        style={{ display: showLoading ? '' : 'none' }}
        spinner={'bubbles'}
        isOpen={showLoading}
        message={'تحميل...'}
      />
    )
  }

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color={'primary'} className="rounded-b-3xl">
          <div className="flex justify-center items-center flex-col gap-4 w-full">
            <div
              style={{ height: '50px' }}
              className={twMerge('flex flex-row justify-center items-center gap-4 mx-auto', isPlatform('ios') && 'my-4')}
            >
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_tick_f.svg'} className="h-6 w-6" />
              <h2 className="p-0">المهام ({name})</h2>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {Loader()}
        {dateTimePicker()}
        {renderPlans()}
      </IonContent>
    </IonPage>
  )
}

export default UserTasks
