import { FC, useRef } from 'react'
import { closeOutline } from 'ionicons/icons'
import { IonModal, IonImg, IonText, IonContent, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react'
import { useAlert } from '../../contexts/AlertContext'

const OfflineAlert: FC = () => {
  const modal = useRef<HTMLIonModalElement>(null)

  const { alert, setAlert } = useAlert()

  // useEffect(() => {
  //   setAlert(true)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const dismiss = () => {
    modal.current?.dismiss()
    setAlert(false)
  }

  return (
    <IonModal id="alert-modal" ref={modal} isOpen={alert}>
      <IonContent className="max-w-lg mx-auto">
        <IonToolbar style={{ '--ion-padding': 0 }}>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => dismiss()}>
              <IonIcon size="large" icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <div className="flex flex-col gap-y-8 items-center w-full">
          <IonImg className="m-auto" src="/assets/icon/ic_offline.svg" />
          <IonText className="text-center text-xl sm:text-2xl font-bold">يوجد خطأ في الاتصال بالإنترنت</IonText>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default OfflineAlert
