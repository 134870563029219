import { IonImg, IonText } from '@ionic/react'

const Slide2: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-[#d8532b]">
      <IonImg
        src="/assets/illustrations/intro/intro3.png"
        className="max-w-[300px] landscape:max-w-[100px] xl:landscape:max-w-[250px]"
      />
      <IonText className="text-center text-2xl font-bold block mt-8 w-3/4 sm:w-1/2 leading-10">
        التطبيق متوفر على جميع الأجهزة،
        <br /> ابدأ القراءة أينما كنت واستمع لأجمل القصص
      </IonText>
    </div>
  )
}

export default Slide2
