import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonButton, IonImg, IonSpinner, IonText, useIonToast } from '@ionic/react'
// hooks
import useAuth from '../../hooks/useAuth'
import { useAlert } from '../../contexts/AlertContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { useSetFirstTime, useSetLoggedIn } from '../../hooks/useIntro'
// errors
import { Errors } from '../../firebase/error-codes'

const Slide4: React.FC = () => {
  const { signInAnonymously } = useAuth()

  const { mutate: setLoggedIn } = useSetLoggedIn()
  const { mutate: setFirstTime } = useSetFirstTime()
  const { connected } = useNetwork()
  const { setAlert } = useAlert()

  const history = useHistory()

  const [present] = useIonToast()

  // const [connected, setConnected] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!connected) {
      setAlert(true)
    } else {
      setAlert(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected])
  // Network.addListener('networkStatusChange', (status) => {
  //   console.log('Network status changed', status)
  //   if (!status.connected) {
  //     setConnected(false)
  //     setAlert(true)
  //   } else {
  //     setConnected(true)
  //     setAlert(false)
  //   }
  // })

  const handleSignInAnonymously = async () => {
    try {
      setLoading(true)
      await signInAnonymously()
      setLoggedIn(true)
      setFirstTime(false)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      console.log(error)
      present({
        message: Errors[error.code] || 'حدث خطأ ما',
        duration: 2000,
        color: 'danger',
        buttons: [{ text: 'إغلاق', handler: () => console.log('close') }]
      })
    }
  }

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-y-20 landscape:gap-y-2 bg-[#f5cf1d]">
      <IonImg
        src="/assets/illustrations/intro/intro4.png"
        className="w-[250px] md:w-[300px] landscape:max-w-[100px] xl:landscape:max-w-[250px]"
      />
      <div className="flex flex-col justify-center items-center gap-y-4 landscape:gap-y-1">
        <IonButton color={'inherit'} className="w-full bg-[#d8532b]" onClick={handleSignInAnonymously}>
          {loading ? (
            <IonSpinner />
          ) : (
            <IonText className="text-2xl" color="inherit">
              إبدأ الآن كضيف
            </IonText>
          )}
        </IonButton>
        <div className="flex justify-center items-center gap-x-4 w-full">
          <div className="w-full h-px bg-gray-300"></div>
          <IonText color="medium">أو</IonText>
          <div className="w-full h-px bg-gray-300"></div>
        </div>
        <IonButton
          color="inherit"
          disabled={!connected}
          className="w-full bg-[#371945]"
          onClick={() => {
            setFirstTime(false)
            history.push('/login')
          }}
        >
          <IonText className="text-2xl text-white" color="inherit">
            تسجيل الدخول
          </IonText>
        </IonButton>
      </div>
    </div>
  )
}

export default Slide4
