export type FirebaseErrors = {
  [key: string]: string
}

export const Errors: FirebaseErrors = {
  'auth/email-already-exists': 'البريد الالكتروني مستخدم',
  'auth/invalid-email': 'البريد الالكتروني خاطئ',
  'auth/user-not-found': 'هناك خطأ في العثور على هذا الحساب',
  'auth/invalid-password': 'تكوين كلمة المرور خاطئ، يجب أن تحتوي على 6 أحرف على الأقل',
  'auth/too-many-requests': 'تم حظر الحساب للعديد من المحاولات الفاشلة، حاول في وقت لاحق',
  'auth/operation-not-allowed': 'خطأ في الاتصال',
  'auth/user-disabled': 'الحساب معطل، يرجى التواصل مع الادارة',
  'auth/wrong-password': 'كلمة المرور خاطئة',
  'auth/email-already-in-use': 'إنّ هذا البريد تم استخدامه سابقاّ',
  'auth/popup-closed-by-user': 'تم إغلاق النافذة من قبل المستخدم',
  'auth/popup-blocked': 'تم حظر النافذة من قبل المتصفح',
  'auth/unauthorized-domain': 'النطاق غير مسموح به',
  'auth/network-request-failed': 'خطأ في الاتصال بالانترنت',
  'auth/no-current-user': 'لا يوجد مستخدم مسجل',
  'auth/account-exists-with-different-credential': 'الحساب موجود بواسطة طريقة تسجيل دخول مختلفة',
  'auth/internal-error': 'خطأ في الاتصال بالانترنت',
  'storage/object-not-found': 'الملف غير موجود',
  'storage/unauthorized': 'غير مصرح لك بالوصول لهذا الملف',
  'storage/canceled': 'تم إلغاء الطلب',
  'storage/unknown': 'خطأ غير معروف',
  'storage/invalid-argument': 'خطأ في الوسيط',
  'storage/internal-error': 'خطأ في الاتصال بالانترنت',
  'firestore/permission-denied': 'غير مصرح لك بالوصول لهذا الملف',
  'firestore/unavailable': 'الخدمة غير متوفرة',
  'firestore/deadline-exceeded': 'تم تجاوز الوقت المحدد',
  'firestore/not-found': 'القصة غير موجودة',
  'firestore/already-exists': 'القصة موجودة بالفعل',
  'firestore/cancelled': 'تم إلغاء الطلب',
  'firestore/unknown': 'خطأ غير معروف',
  'firestore/invalid-argument': 'خطأ في الوسيط',
  'firestore/resource-exhausted': 'تم استنفاذ الموارد',
  'firestore/aborted': 'تم إلغاء الطلب',
  'firestore/unauthenticated': 'غير مصرح لك بالوصول لهذه القصة',
  'firestore/internal-error': 'خطأ في الاتصال بالانترنت',
  'The user canceled the sign-in flow.': 'تم إلغاء عملية تسجيل الدخول من قبلك',
  'The redirect operation has been canceled by the user before finalizing.':
    'تم إلغاء عملية التوجيه من قبل المستخدم قبل الانتهاء',
  'Connection error': 'خطأ في الاتصال بالانترنت',
  'auth/requires-recent-login': 'يجب عليك تسجيل الدخول من جديد لإكمال هذه العملية',
  'Firebase: Error (auth/requires-recent-login).': 'يجب عليك تسجيل الدخول من جديد لإكمال هذه العملية'
}
