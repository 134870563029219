import { FC, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonCheckbox,
  IonSpinner,
  useIonToast
} from '@ionic/react'
import { SocialAuth, ForgotPassModel } from '../../components'
// forms
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// firebase hooks
import { Auth } from '../../firebase/config'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { Errors } from '../../firebase/error-codes'
//
import { useSetFirstTime, useSetLoggedIn } from '../../hooks/useIntro'
//
import useAuth from '../../hooks/useAuth'

interface LoginForm {
  email: string
  password: string
}

const formSchema = yup.object().shape({
  email: yup.string().email('البريد الإلكتروني غير صحيح').required('البريد الإلكتروني مطلوب'),
  password: yup.string().required('كلمة المرور مطلوبة').min(6, 'كلمة المرور أقل من 6 حروف')
})

const Login: FC = () => {
  const { user } = useAuth()

  const { mutate: setLoggedIn } = useSetLoggedIn()
  const { mutate: setFirstTime } = useSetFirstTime()

  const [errMessage, setErrMessage] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm<LoginForm>({
    mode: 'all',
    resolver: yupResolver(formSchema)
  })

  const [signInWithEmailAndPassword, , loginLoading, error] = useSignInWithEmailAndPassword(Auth)

  const [present] = useIonToast()

  const onSubmit = async (data: any) => {
    await signInWithEmailAndPassword(data.email, data.password)
    setLoggedIn(true)
    setFirstTime(false)
    setErrMessage('')
  }

  if (error) {
    console.log('login Error: ', error)
    if (error.message !== errMessage) {
      setErrMessage(error.message)
      present({
        message: Errors[error.code] ? Errors[error.code] : error.message,
        duration: 4000,
        color: 'danger',
        cssClass: 'font-bold text-center'
      })
    }
  }

  if (user) {
    return <Redirect to="/tabs" />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="inherit" style={{ background: '#8cc13a' }}>
          <IonTitle>
            <h2 color="inherit" style={{ color: '#ffffff' }}>
              تسجيل الدخول
            </h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="relative">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle color={'tertiary'}>تسجيل الدّخول</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="ion-padding ion-justify-content-center ion-align-items-center flex flex-col justify-center items-center w-full max-w-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonRow className="ion-margin-top ion-padding-top">
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonInput
                  onIonBlur={() => trigger('email')}
                  label=""
                  placeholder="البريد الإلكتروني"
                  type="email"
                  autocomplete="email"
                  shape="round"
                  color="tertiary"
                  fill="outline"
                  clearInput
                  className="foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none"
                  {...register('email')}
                />
                {errors.email && <IonText className="text-red-500">*&nbsp;{errors.email?.message}</IonText>}
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top ion-margin-top">
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonInput
                  onIonBlur={() => trigger('password')}
                  label=""
                  placeholder="كلمة المرور"
                  type={showPassword ? 'text' : 'password'}
                  shape="round"
                  color="tertiary"
                  fill="outline"
                  clearInput
                  autocomplete="current-password"
                  onIonChange={() => trigger('password')}
                  className="foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none"
                  {...register('password')}
                />
                {errors.password && <IonText className="text-red-500">*&nbsp;{errors.password?.message}</IonText>}
              </IonCol>
            </IonRow>
            <IonRow className="flex flex-row-reverse items-center justify-between p-0 order-1">
              <IonCol size="5" className="p-0">
                <IonButton fill="clear" expand="full" id="open-forgot-password-dialog" color="secondary">
                  <IonText style={{ color: '#371945' }}>نسيت كلمة المرور؟</IonText>
                </IonButton>
              </IonCol>
              <IonCol size="7">
                <IonItem lines="none">
                  <IonCheckbox
                    slot="start"
                    labelPlacement="end"
                    checked={showPassword}
                    className="rounded"
                    onIonChange={(v) => setShowPassword(v.target.checked)}
                  >
                    <IonText style={{ color: '#371945' }}>إظهار كلمة المرور</IonText>
                  </IonCheckbox>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonButton
                  expand="block"
                  shape="round"
                  color="primary"
                  type="submit"
                  size="large"
                  // disabled={!isValid}
                >
                  {loginLoading ? <IonSpinner name="dots" /> : 'تسجيل الدخول'}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonText className="text-center block" style={{ color: '#371945' }}>
                  أو الدخول بواسطة
                </IonText>
              </IonCol>
            </IonRow>
          </form>
          <SocialAuth />
          <ForgotPassModel />
          <hr className="w-1/2 bg-meduim-main" />
          <IonGrid className="ion-padding-top">
            <IonRow className="ion-justify-content-center">
              <IonText style={{ color: '#371945' }} className="text-center block">
                ليس لديك حساب؟
              </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-padding-top">
              <IonButton color={'tertiary'} routerLink="/register" className="ion-align-self-center">
                إنشاء حساب جديد
              </IonButton>
            </IonRow>
          </IonGrid>
          <IonRow className="ion-text-center text-secondary-main self-end mt-8">
            <IonCol size="12" sizeMd="12" sizeLg="12">
              <IonText className="ion-text-center" style={{ color: '#afc1c4' }}>
                جميع الحقوق محفوظة لشركة المستقبل الرقمي وتخضع لجدول التعليمات والصلاحيات.
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login
