import { FC, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { orderBy, filter } from 'lodash'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonSkeletonText,
  isPlatform,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonButton,
  IonSpinner
} from '@ionic/react'
import useAuth from '../../hooks/useAuth'
import useModalState from '../../hooks/useState'
import { useGetUsersBooks } from '../../hooks/useUserQueries'
// components
import { Thumbnail, AddFavoriteButton, ReadButton, DownloadButton, DeleteButton, NotFound } from '../../components'
import { BookWithStatistics } from '../../types/book'
// utils
import { fTime } from '../../utils/format'
import { getDownloadUrl } from '../../utils/getDownloadUrl'
import { checkFolderExistence } from '../../utils/checkFolderExistence'
import { twMerge } from 'tailwind-merge'

type NOT_FOUND_MESSAGES_TYPE = {
  [key: string]: {
    title: string
    subtitle: string
    img: string
  }
}
const NOT_FOUND_MESSAGES: NOT_FOUND_MESSAGES_TYPE = {
  all: {
    title: 'مكتبتك خالية',
    subtitle: 'أضِف كتابك المفضل لتبدأ القراءة',
    img: '/assets/illustrations/opened_book.png'
  },
  downloads: {
    title: 'لم تقم بتحميل أي كتاب',
    subtitle: 'اضغط على زر التحميل لتحميل الكتاب',
    img: '/assets/illustrations/empty_downloads.png'
  },
  favorites: {
    title: 'لم تضف أي كتاب للمفضلة',
    subtitle: 'اضغط على زر المفضلة لإضافة الكتاب للمفضلة',
    img: '/assets/illustrations/empty_favorites.png'
  }
}

const ORDER_BY = [
  { value: 'date', name: 'تاريخ الإضافة' },
  { value: 'pages', name: 'عدد الصفحات' },
  { value: 'title', name: 'عنوان القصة' }
]

const SEGMENTS = [
  { value: 'all', name: 'كتبي' },
  { value: 'downloads', name: 'التحميلات' },
  { value: 'favorites', name: 'المفضلة' }
]

const Skeleton = () => {
  if (isPlatform('desktop'))
    return (
      <IonGrid className="px-1 lg:px-8 container my-10">
        <IonRow className="flex flex-row justify-center items-center gap-y-4 ">
          {Array.from({ length: 6 }).map((_, i) => (
            <IonCol key={`skeleton_main_item_${i}`} sizeXs="12" sizeLg="6" sizeXl="4" size="4">
              <IonCard
                className="w-full h-full rounded-xl shadow-xl mx-auto border border-tertiary-main "
                style={{
                  '--background': 'transparent',
                  '--border-color': 'gray',
                  '--padding-bottom': '4px'
                }}
              >
                <IonCardContent className="flex items-center justify-between">
                  <IonThumbnail className="h-[140px] sm:h-[210px] min-w-[100px] sm:w-[135px]">
                    <IonSkeletonText animated />
                  </IonThumbnail>
                  <IonList className="w-full">
                    {Array.from({ length: 3 }).map((_, i) => (
                      <IonItem key={`skeleton_item_${i}`}>
                        <IonSkeletonText animated className="w-full mx-auto p-1" />
                      </IonItem>
                    ))}
                    <IonItem>
                      <IonSkeletonText animated className="w-1/3 mx-auto p-4" />
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    )
  return (
    <IonList lines="inset">
      {Array.from({ length: 3 }).map((_, i) => (
        <IonItem
          key={`skeleton_main_item_${i}`}
          style={{
            '--background': 'transparent',
            '--border-color': 'gray',
            '--padding-bottom': '4px'
          }}
        >
          <IonThumbnail className="h-[140px] sm:h-[210px] min-w-[100px] sm:w-[135px]">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonList className="w-full">
            {Array.from({ length: 3 }).map((_, i) => (
              <IonItem key={`skeleton_item_${i}`}>
                <IonSkeletonText animated className="w-full mx-auto p-1" />
              </IonItem>
            ))}
            <IonItem>
              <IonSkeletonText animated className="w-1/3 mx-auto p-4" />
            </IonItem>
          </IonList>
        </IonItem>
      ))}
    </IonList>
  )
}

const checkRemainingPages = (pages: number, readPages: number, downloaded: boolean) => {
  if (!downloaded) return 'حمّل القصّة لتتمكّن من قراءتها'
  const remainingPages = pages - readPages
  if (remainingPages === pages || pages === remainingPages) return 'اقرَأ القصّة الآن'
  if (remainingPages === 0) return 'اقرأ القصّة من جديد!'
  if (remainingPages === 1) return 'تبقى صفحة واحدة'
  if (remainingPages === 2) return 'تبقت صفحتان'
  if (remainingPages > 1 && remainingPages < 11) return `تابع القراءة: تبقى ${remainingPages} صفحات`
  return `تابع القراءة: تبقى ${remainingPages} صفحة`
}

const checkPagesRead = (readPages: number, totalPages: number) => {
  if (readPages === totalPages || readPages === 0) return 1
  return readPages
}

const orderBooks = (books: BookWithStatistics[], order: string) => {
  switch (order) {
    case 'date':
      return orderBy(books, ['addedAt'], ['desc'])
    case 'pages':
      return orderBy(books, ['pages'], ['desc'])
    case 'title':
      return orderBy(books, ['title'], ['asc'])
    default:
      return books
  }
}

const filterBooks = (books: BookWithStatistics[], filterName: string) => {
  switch (filterName) {
    case 'all':
      return books
    case 'downloads':
      return filter(books, (book) => book.downloaded)
    case 'favorites':
      return filter(books, (book) => book.isFavorite)
    default:
      return books
  }
}

const renderActionButton = (book: BookWithStatistics, filter: string, uid: string, isLoading: boolean) => {
  if (isLoading) return <IonSkeletonText animated className="w-full mx-auto p-1" />
  if (filter === 'downloads') {
    return (
      <ReadButton
        slot="start"
        bid={book.folderName}
        page={checkPagesRead(book.pageReached, book.pages)}
        readCount={book.readCount}
      />
    )
  }
  if (book.downloaded) {
    return (
      <ReadButton
        slot="start"
        bid={book.folderName}
        page={checkPagesRead(book.pageReached, book.pages)}
        readCount={book.readCount}
      />
    )
  } else if (!book.downloaded && !isLoading) {
    return (
      <DownloadButton
        slot="start"
        uid={uid}
        bid={book.folderName}
        downloadUrl={getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`)}
      />
    )
  }
  return (
    <IonButton slot="start">
      <IonSpinner name="dots" />
    </IonButton>
  )
}

type BookCardProps = {
  uid: string
  filter: string
  book: BookWithStatistics
  isloading: boolean
  onCardClick: (book: BookWithStatistics) => void
}

const BookCard = ({ uid, book, filter, isloading, onCardClick }: BookCardProps) => {
  return (
    <IonCard className="w-full h-full rounded-xl shadow-xl mx-auto border border-tertiary-main">
      <IonCardContent className="flex items-center justify-between">
        <Thumbnail
          bid={book.folderName}
          downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
          onClick={() => {
            onCardClick(book)
          }}
        />
        <IonList className="max-w-full w-full bg-transparent">
          <IonItem className="max-w-full" style={{ '--background': 'transparent' }}>
            <h2 className="w-full font-semibold line-clamp-1 font-29LTKaff-Medium leading-10">{book.title}</h2>
            <AddFavoriteButton slot="end" uid={uid} bookId={book.folderName} isFavorite={book.isFavorite} />
          </IonItem>
          <IonItem style={{ '--background': 'transparent' }}>
            <IonLabel>
              <span className="font-29LTKaff">
                مدة القراءة:{' '}
                <span className="text-tertiary-shade font-semibold">{fTime(book.duration ? book.duration : 0)}</span>
              </span>
            </IonLabel>
          </IonItem>
          <IonItem style={{ '--background': 'transparent' }}>
            <IonLabel>
              <span className="text-tertiary-shade font-semibold text-sm font-29LTKaff">
                {checkRemainingPages(book.pages, book.pageReached, book.downloaded)}
              </span>
            </IonLabel>
          </IonItem>
          <IonItem style={{ '--background': 'transparent' }}>
            {renderActionButton(book, filter, uid, isloading)}
            {/* {book.downloaded ? (
              <ReadButton slot="start" bid={book.folderName} page={checkPagesRead(book.pageReached, book.pages)} />
            ) : (
              !isloading && (
                <DownloadButton
                  slot="start"
                  uid={uid}
                  bid={book.folderName}
                  downloadUrl={getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`)}
                />
              )
            )} */}
            <DeleteButton slot="end" bid={book.folderName} uid={uid} deleteBookDB={filter !== 'downloads'} />
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  )
}

const UserLibrary: FC = () => {
  const { user } = useAuth()

  const { data, isLoading, error, isError } = useGetUsersBooks(user?.uid as string)

  const { dispatch } = useModalState()

  const [books, setBooks] = useState<BookWithStatistics[]>([])
  const [order, setOrder] = useState<string>(ORDER_BY[0].value)
  const [filter, setFilter] = useState<string>(SEGMENTS[0].value)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const orderAndFilterBooks = async () => {
      if (data) {
        const booksWithDownloadedProperty = await Promise.all(
          data.map(async (book) => {
            const folder = await checkFolderExistence(book.folderName)
            book.downloaded = folder
            return book
          })
        )
        const orderedBooks = orderBooks(booksWithDownloadedProperty, order)
        const filteredBooks = filterBooks(orderedBooks, filter)
        setBooks(filteredBooks)
        setLoaded(true)
      }
    }
    orderAndFilterBooks()
  }, [data, order, filter])

  if (error || isError) {
    console.log('db error', error, isError)
  }

  const handleIonSelectChange = (e: any) => {
    console.log('order changed')
    setOrder(e.detail.value)
  }

  const handleFilterChange = (e: any) => {
    setFilter(e.detail.value)
  }

  if (!user) return <Redirect to="/login" />

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color={'userLibrary'} className="rounded-b-3xl" style={{ '--padding-bottom': '0px' }}>
          <div className="flex justify-center items-center flex-col gap-4 w-full">
            <div
              className={twMerge(
                'flex flex-row justify-center items-center gap-4 mx-auto',
                isPlatform('ios') && 'my-4'
              )}
            >
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_book_f.svg'} color="inherit" className="h-6 w-6" />
              <h2 className="p-0">مكتَبَتي</h2>
            </div>
            <IonSegment value={filter} className="px-4 sm:px-8 text-3xl w-full" onIonChange={handleFilterChange}>
              {SEGMENTS.map((s, idx) => (
                <IonSegmentButton
                  key={`${s.name}__${idx}`}
                  value={s.value}
                  className=""
                  style={{
                    '--indicator-height': isPlatform('ios') ? '2px' : '3px',
                    '--indicator-transform': isPlatform('ios') ? 'translate3d(0, 27px, 0)' : ''
                  }}
                  //'--indicator-transform': 'translate3d(0, 25px, 0)'
                >
                  <IonLabel>
                    <span className="text-lg sm:text-xl">{s.name}</span>
                  </IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="flex flex-row items-center justify-between w-full px-8">
          <IonLabel>{books?.length}&nbsp;&nbsp;قصص</IonLabel>
          <IonList>
            <IonItem>
              <IonSelect
                aria-label="Order By"
                interface="popover"
                placeholder={ORDER_BY[0].name}
                onIonChange={handleIonSelectChange}
              >
                {ORDER_BY.map((o, idx) => (
                  <IonSelectOption key={`${o.name}__${idx}`} value={o.value}>
                    {o.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
        </div>
        {isLoading || !loaded ? (
          <Skeleton />
        ) : isPlatform('desktop') ? (
          <IonGrid className="px-1 lg:px-8 container my-10">
            <IonRow className="flex flex-row justify-center items-center gap-y-4 ">
              {books?.map((book, idx) => (
                <IonCol key={`${book}__${idx}`} sizeXs="12" sizeLg="6" sizeXl="4" size="4">
                  <BookCard
                    isloading={isLoading}
                    uid={user?.uid as string}
                    book={book}
                    filter={filter}
                    onCardClick={() => {
                      dispatch({ type: 'SHOW', payload: book.folderName })
                    }}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        ) : (
          <IonList lines="inset" className="space-y-2">
            {books?.map((book, idx) => (
              <IonItem
                key={`${book}__${idx}`}
                style={{
                  '--background': 'transparent',
                  '--border-style': books.length - 1 === idx ? 'none' : 'solid',
                  '--border-color': '#1b85b6',
                  '--padding-bottom': '4px'
                }}
              >
                <Thumbnail
                  bid={book.folderName}
                  downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                  onClick={() => {
                    dispatch({ type: 'SHOW', payload: book.folderName })
                  }}
                />
                <IonList className="max-w-full w-full bg-transparent">
                  <IonItem className="max-w-full" style={{ '--background': 'transparent' }}>
                    <h4 className="w-full font-semibold line-clamp-1 font-29LTKaff-Light leading-10 text-lg">
                      {book.title}
                    </h4>
                    <AddFavoriteButton
                      slot="end"
                      uid={user?.uid}
                      bookId={book.folderName}
                      isFavorite={book.isFavorite}
                    />
                  </IonItem>
                  <IonItem style={{ '--background': 'transparent' }}>
                    <IonLabel>
                      <span className="font-29LTKaff">
                        مدة القراءة:{' '}
                        <span className="text-tertiary-shade font-semibold">
                          {fTime(book.duration ? book.duration : 0)}
                        </span>
                      </span>
                    </IonLabel>
                  </IonItem>
                  <IonItem style={{ '--background': 'transparent' }}>
                    <IonLabel>
                      <span className="text-tertiary-shade font-semibold text-sm font-29LTKaff">
                        {checkRemainingPages(book.pages, book.pageReached, book.downloaded)}
                      </span>
                    </IonLabel>
                  </IonItem>
                  <IonItem style={{ '--background': 'transparent' }}>
                    {renderActionButton(book, filter, user.uid, isLoading)}
                    {/* {book.downloaded ? (
                      <ReadButton
                        slot="start"
                        bid={book.folderName}
                        page={checkPagesRead(book.pageReached, book.pages)}
                      />
                    ) : (
                      !isLoading && (
                        <DownloadButton
                          slot="start"
                          uid={user.uid}
                          bid={book.folderName}
                          downloadUrl={getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`)}
                        />
                      )
                    )} */}
                    <DeleteButton
                      slot="end"
                      bid={book.folderName}
                      uid={user.uid}
                      deleteBookDB={filter !== 'downloads'}
                    />
                  </IonItem>
                </IonList>
              </IonItem>
            ))}
          </IonList>
        )}
        {loaded && !isLoading && books.length === 0 && (
          <NotFound
            cta={filter === 'all'}
            title={NOT_FOUND_MESSAGES[filter].title}
            subtitle={NOT_FOUND_MESSAGES[filter].subtitle}
            img={NOT_FOUND_MESSAGES[filter].img}
          />
        )}
      </IonContent>
    </IonPage>
  )
}

export default UserLibrary
