import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
// import LibraryAPI from '../firebase/services/library_DEPRECATED'
import { Book } from '../types/book'
import { filter, find } from 'lodash'
import { freeBooks, highlightedBooks, favoriteLevelBooks, bookOfTheWeek, getBookWihID } from '../api'

// initialize query with key "books"
export const useBooks = () => {
  return useQuery({
    queryKey: ['books'],
    queryFn: async () => {
      return []
    }
  })
}

export const useGetBook = (id: string) => {
  const queryClient = useQueryClient()
  const books = queryClient.getQueryData(['books']) as Book[]
  // console.log('useGetBooks books id:', id, JSON.stringify(books))
  const book = find(books, (b: Book) => b.folderName === id)
  useQuery({
    queryKey: ['books', id],
    queryFn: async () => {
      //REMOVEME: this code related to firestore
      // const book = await LibraryAPI().getBook(id)
      //REMOVEME: this code related to firestore
      const book = await getBookWihID(id)
      queryClient.setQueryData(['books', id], book)
      queryClient.setQueryData(['books'], (old: any) => {
        const newBooks = [...filter(old, (b: Book) => b.folderName !== book.folderName), book]
        return newBooks
      })
      return book
    },
    enabled: !book && !!id
  })
  return book
}

export const useGetFreeBooks = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['books'],
    mutationFn: async (books: Book[]) => {
      queryClient.setQueryData(['books'], (old: any) => {
        // merge two arrays of books by using reduce
        const newBooks = books.reduce((acc: any, book: any) => {
          const found = acc.find((b: any) => b.folderName === book.folderName)
          if (!found) {
            return [...acc, book]
          }
          return acc
        }, old)
        return newBooks
        // const newBooks = [...filter(old, (b: Book) => b.folderName !== book.folderName), book]
        // return newBooks
      })
    }
  })

  return useQuery({
    queryKey: ['free'],
    queryFn: async () => {
      //REMOVEME: this code related to firestore
      // const books = await LibraryAPI().getFreeBooks()
      //REMOVEME: this code related to firestore
      const books = await freeBooks()
      mutate(books)
      // books.map((book) => {
      //   return mutate(book)
      //   // return queryClient.setQueryData(['free', book.folderName], { ...book, free: true })
      // })
      return books
    }
  })
}

export const useGetHighlightedBooks = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['books'],
    mutationFn: async (books: Book[]) => {
      queryClient.setQueryData(['books'], (old: any) => {
        // merge two arrays of books by using reduce
        const newBooks = books.reduce((acc: any, book: any) => {
          const found = acc.find((b: any) => b.folderName === book.folderName)
          if (!found) {
            return [...acc, book]
          }
          return acc
        }, old)
        return newBooks
        // const newBooks = [...filter(old, (b: Book) => b.folderName !== book.folderName), book]
        // return newBooks
      })
    }
  })

  return useQuery({
    queryKey: ['highlights'],
    queryFn: async () => {
      //REMOVEME: this code related to firestore
      // const books = await LibraryAPI().getHighlightedBooks()
      //REMOVEME: this code related to firestore
      const books = await highlightedBooks()
      mutate(books)
      // books.map((book) => {
      //   return mutate(book)
      //   // return queryClient.setQueryData(['highlights', book.folderName], book)
      // })
      return books
    }
  })
}

export const useGetFavoriteLevelBooks = (uid: string) => {
  console.log('useGetFavoriteLevelBooks uid', uid)
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['books'],
    mutationFn: async (books: Book[]) => {
      queryClient.setQueryData(['books'], (old: any) => {
        // merge two arrays of books by using reduce
        console.log('useGetFavoriteLevelBooks', books)
        const newBooks = books.reduce((acc: any, book: any) => {
          const found = acc.find((b: any) => b.folderName === book.folderName)
          if (!found) {
            return [...acc, book]
          }
          return acc
        }, old)
        return newBooks
      })
      // queryClient.setQueryData(['books'], (old: any) => {
      //   const newBooks = [...filter(old, (b: Book) => b.folderName !== book.folderName), book]
      //   return newBooks
      // })
      // return book
    }
  })
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['user', 'favorites'],
    queryFn: async () => {
      //REMOVEME: this code related to firestore
      // const books = await LibraryAPI().getFavoriteLevelBooks(uid)
      //REMOVEME: this code related to firestore
      const books = await favoriteLevelBooks(uid)
      mutate(books)
      // books.map((book) => {
      //   return mutate(book)
      //   // return queryClient.setQueryData(['favorites', book.folderName], book)
      // })
      return books
    },
    enabled: !!uid
  })
}

export const useGetBookOfTheWeek = (uid: string) => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['books'],
    mutationFn: async (book: Book) => {
      queryClient.setQueryData(['books'], (old: any) => {
        const newBooks = [...filter(old, (b: Book) => b.folderName !== book.folderName), book]
        return newBooks
      })
    }
  })
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['user', 'bookOfTheWeek'],
    queryFn: async () => {
      //REMOVEME: this code related to firestore
      // const book = await LibraryAPI().getBookOfTheWeek(uid)
      //REMOVEME: this code related to firestore
      const book = await bookOfTheWeek(uid)
      // queryClient.setQueryData(['bookOfTheWeek', book.folderName], book)
      mutate(book)
      return book
    },
    enabled: !!uid
  })
}
