import { FC, useRef } from 'react'
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonModal,
  IonImg,
  IonText,
  IonRow
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import useModalState from '../../hooks/useState'
import { useHistory } from 'react-router'

interface Props {
  isOpen: boolean
  message: string
  onDismiss: () => void
}

const SignInModal: FC<Props> = ({ isOpen, message, onDismiss }) => {
  const { dispatch } = useModalState()

  const history = useHistory()

  const modal = useRef<HTMLIonModalElement>(null)

  const dismiss = () => {
    modal.current?.dismiss()
  }

  return (
    <IonModal id="alert-modal" ref={modal} isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonContent className="max-w-lg mx-auto">
        <IonToolbar style={{ '--ion-padding': 0 }}>
          <IonButtons slot="end">
            <IonButton color="light" onClick={() => dismiss()}>
              <IonIcon size="large" icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonGrid className="justify-between w-full items-center space-y-4">
          <IonRow>
            <IonImg className="m-auto" src="/assets/illustrations/alert.png" />
          </IonRow>
          <IonRow>
            <IonText className="text-center text-xl sm:text-2xl font-bold">{message}</IonText>
          </IonRow>
          <IonRow>
            <IonButton
              className="w-[200px] m-auto"
              color={'primary'}
              onClick={() => {
                modal.current?.dismiss()
                dispatch({
                  type: 'HIDE',
                  payload: ''
                })
                history.push({ pathname: '/register/anonymous' })
              }}
            >
              إنشاء حساب
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default SignInModal
