type ServerMessages = {
  [key: string]: string
}

export const Messages: ServerMessages = {
  //--- AUTH MESSAGES ---//
  unauthorized: 'غير مصرح لك ',
  //--- PROMO CODE MESSAGES ---//
  promoSuccess: 'تم تفعيل الرمز بنجاح!',
  promoError: 'تعثرت عملية التفعيل، يرجى التواصل مع فريق قصصي.',
  'Promo code is already used by another user': 'إنّ رمز التفعيل هذا مستخدم!',
  'Promo code is not valid': 'رمز التفعيل غير صالح للاستخدام',
  'Promo code is expired': 'إنّ رمز التفعيل هذا منتهي الصلاحية',
  //--- USER MESSAGES ---//
  'User deletion failed!': 'فشل حذف الحساب!',
  'User deleted successfully!': 'تم حذف الحساب بنجاح!'
}
