import { useQueryClient, useMutation } from '@tanstack/react-query'
// import {search, getAllBooks } from '../firebase/services/search'
import { getAllBooks, search } from '../api'

type QueryProps = {
  text: string
  content: boolean
}
export const useSearch = ({ text, content }: QueryProps) => {
  // console.log('useSearch', text.length)
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['books'],
    mutationFn: async () => {
      const res = await search({ text, content })
      queryClient.setQueryData(['books'], (old: any) => {
        // merge two arrays of books by using reduce and replace new books with old ones
        const newBooks = res.reduce((acc: any, book: any) => {
          const found = acc.find((b: any) => b.folderName === book.folderName)
          if (!found) {
            return [...acc, book]
          }
          return acc
        }, old)
        return newBooks
        // const newBooks = [...old, ...res]
        // return newBooks
      })
      return res
    }
  })
}

export const useGetAllBooks = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['books'],
    mutationFn: async () => {
      const res = await getAllBooks()
      queryClient.setQueryData(['books'], res)
      return res.reduce((acc: any, doc) => {
        const book = doc
        const level = book.level
        if (!acc[level]) {
          acc[level] = { level, books: [] }
        }
        acc[level].books.push(book)
        return acc
      }, [])
    }
  })
}
