import { FC } from 'react'
import { IonicSlides, IonThumbnail, IonSkeletonText, useIonToast, isPlatform } from '@ionic/react'
// components
import { Thumbnail } from '../../components'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react'
// hooks
import useModalState from '../../hooks/useState'
import { useGetHighlightedBooks } from '../../hooks/useLibrary'

const HighlightedBooks: FC = () => {
  const {
    data: highlightedBooks,
    isLoading: highlightedBooksLoading,
    error: highlightedBooksError
  } = useGetHighlightedBooks()

  const { dispatch } = useModalState()

  const [presentToast] = useIonToast()

  if (highlightedBooksError) {
    console.log('error', highlightedBooksError)
    presentToast({
      message: highlightedBooksError as string,
      duration: 3000,
      color: 'danger',
      cssClass: 'text-center'
    })
  }

  const options = {
    slidesPerView: 3,
    spaceBetween: 10,
    // centeredSlides: true,
    grabCursor: true,
    // slidesOffsetBefore: 0,
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 20
      },
      1280: {
        slidesPerView: 8,
        spaceBetween: 20
      },
      1536: {
        slidesPerView: 9,
        spaceBetween: 20
      }
    }
  }

  return (
    <div className="my-4">
      <h2 className="mb-4 text-2xl">عناوين مميّزَة</h2>
      {highlightedBooksLoading ? (
        <div className="flex flex-row gap-x-2">
          {Array.from({ length: isPlatform('desktop') ? 10 : 5 }).map((_, i) => (
            <div key={i} className="flex flex-col justify-between items-center w-full">
              <IonThumbnail className="h-[140px] sm:h-[210px] w-[100px] sm:w-[135px]">
                <IonSkeletonText animated />
              </IonThumbnail>
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          dir="rtl"
          className="px-2"
          {...options}
          slidesPerView={'auto'}
          spaceBetween={10}
          modules={[IonicSlides]}
        >
          {highlightedBooks?.map((book, idx) => (
            <SwiperSlide key={`${book.title}__${idx}`} className="flex flex-col pb-8">
              {/* <Link to={`/book/${book.folderName}`}> */}
              <Thumbnail
                withShadow
                bid={book.folderName}
                downloadUrl={`stories/Level${book.level}/${book.folderName}/cover.jpg`}
                onClick={() => {
                  dispatch({ type: 'SHOW', payload: book.folderName })
                }}
              />
              {/* </Link> */}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default HighlightedBooks
