import { Suspense, lazy, useEffect } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { IonReactRouter } from '@ionic/react-router'
import { App as CapApp } from '@capacitor/app'
import { Preferences } from '@capacitor/preferences'
import { IonApp, setupIonicReact, IonRouterOutlet, isPlatform, useIonRouter } from '@ionic/react'
// components
import { PrivateRoute, BookDetails, Loader, OfflineAlert } from './components'
// hooks
import useAuth from './hooks/useAuth'
import { useBooks } from './hooks/useLibrary'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
/* Theme variables */
import './theme/variables.css'
import './theme/globals.css'
import './theme/fonts.css'
// APP PAGES
import Tabs from './pages/Tabs'
import Introduction from './pages/Introduction'
// hooks
import { useFirstTime, useLoggedIn, useSetFirstTime } from './hooks/useIntro'
import { useNetwork } from './contexts/NetworkContext'

setupIonicReact()

const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full bg-white z-50">
          <img src="assets/loader.gif" className="logo" alt={'loader gif'} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

const Login = Loadable(lazy(() => import('./pages/Authentication/Login')))
const Register = Loadable(lazy(() => import('./pages/Authentication/Register')))
const Story = Loadable(lazy(() => import('./pages/Story/Story')))
const Subscription = Loadable(lazy(() => import('./pages/Subscription/Subscription')))
const Promocode = Loadable(lazy(() => import('./pages/Subscription/Promocode')))
const ContactUs = Loadable(lazy(() => import('./pages/Support/ContactUs')))
const Profile = Loadable(lazy(() => import('./pages/Profile')))

const App: React.FC = () => {
  const router = useIonRouter()
  const history = useHistory()

  const { user } = useAuth()
  const { connected } = useNetwork()
  const { data: loggedIn } = useLoggedIn()
  const { data: firstTime } = useFirstTime()
  const { mutate: setFirstTime } = useSetFirstTime()

  const LOCATION = window.location.pathname

  const isSupport = LOCATION.includes('support')

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(-1, () => {
      if (router.canGoBack()) {
        router.goBack()
      } else {
        if (isPlatform('hybrid')) {
          CapApp.exitApp()
        } else {
          history.replace('/tabs/library')
        }
      }
    })
  })

  const getPreferences = async () => {
    const isFirstTime = await Preferences.get({ key: 'firstTime' })
    const isLogged = await Preferences.get({ key: 'loggedIn' })
    return { isFirstTime, isLogged }
  }

  useEffect(() => {
    if (isPlatform('hybrid'))
      getPreferences().then((res) => {
        !res['isLogged'].value ? setFirstTime(true) : setFirstTime(false)
      })
    else {
      !loggedIn && setFirstTime(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // init books query before app render
  const { isLoading } = useBooks()

  if (isLoading) {
    return <Loader />
  }

  return (
    <IonApp>
      <IonReactRouter>
        {firstTime && !isSupport ? (
          <Introduction />
        ) : (
          <IonRouterOutlet dir="rtl">
            {/* <Switch> */}
            <Route
              path="/login"
              component={Login}
              // render={(props) => (
              //   <AuthGuard>
              //     <Login {...props} />
              //   </AuthGuard>
              // )}
            />
            <Route
              exact
              path="/register"
              component={Register}
              // render={(props) => (
              //   <AuthGuard>
              //     <Register {...props} />
              //   </AuthGuard>
              // )}
            />
            <Route
              exact
              path="/register/anonymous"
              component={Register}
              // render={(props) => (
              //   <AnonymousGuard>
              //     <Register {...props} />
              //   </AnonymousGuard>
              // )}
            />

            <Route path="/tabs" component={Tabs} />
            {/* <PrivateRoute path="/tabs" component={Tabs} /> */}
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/subscription" component={Subscription} />
            <PrivateRoute exact path="/subscription/promo" component={Promocode} />
            <PrivateRoute exact path="/subscription" component={Subscription} />
            <PrivateRoute path="/story/:storyId/:pageNum/:readCount/:editMode" component={Story} />
            <Route exact path="/support/contact-us" component={ContactUs} />

            <Route
              exact
              path="/"
              render={() => <Redirect exact to={connected ? '/tabs/library' : '/tabs/user-library'} />}
            />
            <Route render={() => <Redirect exact from="/" to={connected ? '/tabs/library' : '/tabs/user-library'} />} />
            {/* <Route
              render={() => (
                <IonPage className="flex justify-center items-center h-full bg-white">
                  <IonContent>
                    <div className="flex justify-center items-center h-full">
                      <h2>404</h2>
                    </div>
                  </IonContent>
                </IonPage>
              )}
            /> */}
            {/* </Switch> */}
          </IonRouterOutlet>
        )}
        {user && <BookDetails />}
        <OfflineAlert />
      </IonReactRouter>
    </IonApp>
  )
}

export default App
