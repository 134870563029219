import { FC } from 'react'
import { IonSkeletonText, IonThumbnail, IonicSlides, isPlatform, useIonToast } from '@ionic/react'
// components
import { Thumbnail } from '../../components'
// siwper
import { Swiper, SwiperSlide } from 'swiper/react'
// hooks
import { useGetFavoriteLevelBooks } from '../../hooks/useLibrary'
import useModalState from '../../hooks/useState'
import useAuth from '../../hooks/useAuth'

const FavoriteBooks: FC = () => {
  const { user } = useAuth()
  const {
    data: favoriteLevelBooks,
    error: favoriteLevelBooksError,
    isLoading: favoriteLevelBooksIsLoading
  } = useGetFavoriteLevelBooks(user.uid)

  const { dispatch } = useModalState()

  const [presentToast] = useIonToast()

  if (favoriteLevelBooksError) {
    console.log('error', favoriteLevelBooksError)
    presentToast({
      message: favoriteLevelBooksError as string,
      duration: 3000,
      color: 'danger',
      cssClass: 'text-center'
    })
  }
  const options = {
    slidesPerView: 3,
    spaceBetween: 10,
    // centeredSlides: true,
    grabCursor: true,
    // slidesOffsetBefore: 0,
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 20
      },
      1280: {
        slidesPerView: 8,
        spaceBetween: 20
      },
      1536: {
        slidesPerView: 9,
        spaceBetween: 20
      }
    }
  }

  return (
    <div className="my-4">
      <h2 className="mb-4 text-2xl">عَناوين تنالُ إعجابَك</h2>
      {favoriteLevelBooksIsLoading ? (
        <div className="flex flex-row gap-x-2">
          {Array.from({ length: isPlatform('desktop') ? 10 : 5 }).map((_, i) => (
            <div key={i} className="flex flex-col justify-between items-center w-full">
              <IonThumbnail className="h-[140px] sm:h-[210px] w-[100px] sm:w-[135px]">
                <IonSkeletonText animated />
              </IonThumbnail>
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          dir="rtl"
          className="px-2"
          {...options}
          slidesPerView={'auto'}
          spaceBetween={10}
          modules={[IonicSlides]}
        >
          {favoriteLevelBooks?.map((book, idx) => (
            <SwiperSlide key={`${book.title}__${idx}`} className="flex flex-col pb-8">
              {/* <Link to={`/book/${book.folderName}`}> */}
              <Thumbnail
                withShadow
                bid={book.folderName}
                downloadUrl={`stories/Level${book.level}/${book.folderName}/cover.jpg`}
                onClick={() => {
                  dispatch({ type: 'SHOW', payload: book.folderName })
                }}
              />
              {/* </Link> */}
              {/* <p className="text-center w-11/12 truncate ">{book.title}</p> */}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default FavoriteBooks
