import { FC } from 'react'
import { IonButton, IonIcon, IonSpinner, useIonAlert, useIonToast } from '@ionic/react'
// capacitor plugins
import { Filesystem, Directory } from '@capacitor/filesystem'
// hooks
import { useUserDeleteBook, useUserEditBookStats } from '../../hooks/useUserQueries'

interface DeleteButtonProps {
  uid: string
  bid: string
  slot?: string
  deleteBookDB?: boolean
}

const DeleteButton: FC<DeleteButtonProps> = ({ bid, uid, slot, deleteBookDB }) => {
  const { mutate: deleteBook, isError, isLoading } = useUserDeleteBook({ uid, bid })
  const { mutate: editBookStats, isLoading: isEditing } = useUserEditBookStats(uid, bid)

  const [present] = useIonAlert()
  const [presentToast] = useIonToast()

  if (isError) console.log('Error in Delete Book', isError)

  const handleDelete = () => {
    present({
      header: 'حذف القصة',
      message: deleteBookDB ? 'هل أنت متأكد من إزالة القصة من مكتبتك؟' : 'هل أنت متأكد من حذف القصة من جهازك؟',
      buttons: [
        { text: 'إلغاء', role: 'cancel', cssClass: 'alert-cancel-button' },
        {
          text: 'حذف',
          handler: () => {
            if (deleteBookDB) deleteBook()
            else editBookStats({ downloaded: false })
            checkAndDeleteDirectory(`${bid}`)
          },
          cssClass: 'alert-delete-button'
        }
      ]
    })
  }

  async function checkAndDeleteDirectory(directoryPath: string): Promise<void> {
    try {
      const directoryExists = await Filesystem.readdir({
        path: directoryPath,
        directory: Directory.Data
      })

      if (directoryExists && directoryExists.files.length > 0) {
        // Directory exists and is not empty, delete its contents
        await Filesystem.rmdir({
          path: directoryPath,
          directory: Directory.Data,
          recursive: true
        })
      }
      presentToast({
        message: 'تم حذف القصة بنجاح',
        duration: 2000,
        color: 'success',
        position: 'top'
      })

      console.log(`Directory '${directoryPath}' deleted successfully.`)
    } catch (error) {
      console.error(`Error while deleting directory '${directoryPath}':`, error)
    }
  }

  return (
    <IonButton
      slot={slot}
      color="danger"
      fill="clear"
      size="small"
      onClick={(e) => {
        e.stopPropagation()
        handleDelete()
      }}
    >
      {isLoading || isEditing ? (
        <IonSpinner name="dots" />
      ) : (
        <IonIcon slot="icon-only" aria-hidden="true" icon={'/assets/icon/ic_delete.svg'} className="h-6 w-6" />
      )}
    </IonButton>
  )
}

export default DeleteButton
