import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
// App Providers
import { AuthProvider } from './contexts/AuthContext'
import { StateProvider } from './contexts/StateContext'
import { AlertProvider } from './contexts/AlertContext'
import { ImageProvider } from './contexts/ImageContext'
import { NetworkProvider } from './contexts/NetworkContext'
import { DownloadProvider } from './contexts/DownloadContext'
// React Query Devtools
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
//
import { defineCustomElements } from '@ionic/pwa-elements/loader'
// swiper styles
import 'swiper/css'
import 'swiper/css/zoom'
import 'swiper/css/autoplay'
import 'swiper/css/keyboard'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/virtual'
import 'swiper/css/effect-creative'
import '@ionic/react/css/ionic-swiper.css'
// app styles
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      networkMode: 'offlineFirst'
    },
    mutations: {
      networkMode: 'offlineFirst'
    }
  }
})

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)

// const ReactQueryDevtoolsProduction = React.lazy(() =>
//   import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
//     default: d.ReactQueryDevtools
//   }))
// )

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <NetworkProvider>
          <AuthProvider>
            <StateProvider>
              <DownloadProvider>
                <ImageProvider>
                  <App />
                  {/* <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                  </React.Suspense> */}
                  {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                </ImageProvider>
              </DownloadProvider>
            </StateProvider>
          </AuthProvider>
        </NetworkProvider>
      </AlertProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
