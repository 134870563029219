import { App } from '@capacitor/app'
import { twMerge } from 'tailwind-merge'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, Redirect, useLocation, Route } from 'react-router-dom'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, CreateAnimation } from '@ionic/react'
// Tabs
import Search from './Search'
import Library from './Library'
import UserLibrary from './UserLibrary'
import UserBookmarks from './UserBookmarks'
import UserTasks from './UserTasks'
// hooks
// import { useAlert } from '../../contexts/AlertContext'
import { useNetwork } from '../../contexts/NetworkContext'

const Tabs: FC<RouteComponentProps> = ({ match }) => {
  const { url } = match

  // const history = useHistory()

  // const { setAlert } = useAlert()
  const { connected } = useNetwork()

  // const [isConnected, setIsConnected] = useState<boolean>(true)

  // Network.addListener('networkStatusChange', (status) => {
  //   console.log('Network status changed', status)
  //   setIsConnected(status.connected)
  // })

  App.addListener('backButton', ({ canGoBack }) => {
    console.log('backButton: ', location.pathname, canGoBack)
    if (!canGoBack) {
      App.exitApp()
    } else {
      window.history.back()
    }
  })

  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState<string>('library')

  useEffect(() => {
    const tab = location.pathname.split('/')[2]
    setSelectedTab(tab)
  }, [location])

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab)
  }

  const isSelected = (tab: string) => {
    return selectedTab === tab
  }

  const revealAnimation = {
    property: 'transform',
    fromValue: 'translateX(-30px)',
    toValue: 'translateX(0px)'
  }

  const switchAnimation = {
    duration: 200,
    iterations: 1,
    fromTo: [revealAnimation],
    easing: 'ease-in-out'
  }

  return (
    <IonTabs>
      <IonRouterOutlet dir="rtl">
        {/* <Switch> */}
        {/* <Route exact path={url} render={() => <Redirect to={`${url}/library`} />} /> */}
        <Redirect exact path={url} to={`${url}/library`} />
        <Route exact path={`${url}/library`} component={Library} />
        <Route exact path={`${url}/user-library`} component={UserLibrary} />
        <Route exact path={`${url}/bookmarks`} component={UserBookmarks} />
        <Route exact path={`${url}/search`} component={Search} />
        <Route exact path={`${url}/tasks`} component={UserTasks} />
        {/* <Route render={() => <Redirect to={match.url} />} /> */}
        {/* </Switch> */}
      </IonRouterOutlet>
      <IonTabBar className="px-4" onIonTabsDidChange={(e) => handleTabChange(e.detail.tab)} slot="bottom">
        <IonTabButton
          // disabled={!isConnected}
          tab="library"
          href="/tabs/library"
          className={twMerge(isSelected('library') ? 'transition-all duration-500 ease-in-out' : '', 'p-1')}
        >
          {isSelected('library') ? (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_home_f.svg'} color="inherit" />
              <IonLabel className="font-Tajawal" style={{ color: '#707070' }}>
                المكتبة
              </IonLabel>
            </CreateAnimation>
          ) : (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_home_o.svg'} />
            </CreateAnimation>
          )}
        </IonTabButton>
        <IonTabButton
          tab="user-library"
          href="/tabs/user-library"
          className={twMerge(isSelected('user-library') ? 'transition-all duration-500 ease-in-out' : '', 'p-1')}
        >
          {isSelected('user-library') ? (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_library_f.svg'} color="inherit" />
              <IonLabel className="font-Tajawal" style={{ color: '#707070' }}>
                مكتبتي
              </IonLabel>
            </CreateAnimation>
          ) : (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_library_o.svg'} />
            </CreateAnimation>
          )}
        </IonTabButton>
        <IonTabButton
          tab="bookmarks"
          href="/tabs/bookmarks"
          className={twMerge(isSelected('bookmarks') ? 'transition-all duration-500 ease-in-out' : '', 'p-1')}
        >
          {isSelected('bookmarks') ? (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_notebook_f.svg'} color="inherit" />
              <IonLabel className="font-Tajawal" style={{ color: '#707070' }}>
                مفكرتي
              </IonLabel>
            </CreateAnimation>
          ) : (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_notebook_o.svg'} />
            </CreateAnimation>
          )}
        </IonTabButton>
        <IonTabButton
          disabled={!connected}
          tab="tasks"
          href="/tabs/tasks"
          className={twMerge(isSelected('tasks') ? 'transition-all duration-500 ease-in-out' : '', 'p-1')}
        >
          {isSelected('tasks') ? (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/nav_tick_f.svg'} color="inherit" />
              <IonLabel className="font-Tajawal" style={{ color: '#707070' }}>
                المهام
              </IonLabel>
            </CreateAnimation>
          ) : (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/nav_tick_o.svg'} color="inherit" className="p-1" />
            </CreateAnimation>
          )}
        </IonTabButton>
        <IonTabButton
          disabled={!connected}
          tab="search"
          href="/tabs/search"
          className={twMerge(isSelected('search') ? 'transition-all duration-500 ease-in-out' : '', 'p-1')}
        >
          {isSelected('search') ? (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_search_f.svg'} color="inherit" />
              <IonLabel className="font-Tajawal" color="inherit" style={{ color: '#707070' }}>
                بحث
              </IonLabel>
            </CreateAnimation>
          ) : (
            <CreateAnimation {...switchAnimation}>
              <IonIcon aria-hidden="true" icon={'/assets/icon/ic_nav_search_o.svg'} color="inherit" className="p-1" />
            </CreateAnimation>
          )}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default Tabs
