import { FC } from 'react'
import { IonCard, IonCardTitle, IonCardSubtitle, IonCardContent, IonImg } from '@ionic/react'

interface Props {
  title: string
  subtitle: string
  img: string
  cta?: boolean
}

const NotFound: FC<Props> = ({ title, subtitle, img }) => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[300px] mx-2">
      <IonCard className="rounded-xl flex flex-col justify-between items-center py-6 gap-y-4 w-full max-w-xl">
        <IonCardTitle>{title}</IonCardTitle>
        <IonCardSubtitle>{subtitle}</IonCardSubtitle>
        <IonCardContent>
          <IonImg src={img} className="w-[150px] mt-4" />
        </IonCardContent>
      </IonCard>
    </div>
  )
}

export default NotFound
