import { initializeApp } from 'firebase/app'
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
  GoogleAuthProvider,
  signInWithCredential
} from 'firebase/auth'
import { getFirestore, initializeFirestore, persistentLocalCache } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { Capacitor } from '@capacitor/core'

const firebaseConfig = {
  apiKey: 'AIzaSyATyUps9qdo71ws6horThw76WvaGJXam1w',
  authDomain: 'stories-app-qissasi.firebaseapp.com',
  projectId: 'stories-app-qissasi',
  storageBucket: 'stories-app-qissasi.appspot.com',
  messagingSenderId: '185023152080',
  appId: '1:185023152080:web:3ce42e37e9bd20c879c3d5',
  measurementId: 'G-K6C4RT46DL'
}

const app = initializeApp(firebaseConfig)

initializeFirestore(app, {
  localCache: persistentLocalCache(),
  experimentalForceLongPolling: true

  // cacheSizeBytes: CACHE_SIZE_UNLIMITED,
})

export const firestore = getFirestore(app)

export const storage = getStorage(app)

// export const Auth = getAuth(app)

export const Auth = Capacitor.isNativePlatform()
  ? initializeAuth(app, { persistence: indexedDBLocalPersistence })
  : getAuth(app)

export const functions = getFunctions(app)

interface AuthCredential {
  idToken: string
  accessToken?: string
}

export const signInWithCredential_ = async (credential: AuthCredential) => {
  const { user } = await signInWithCredential(
    Auth,
    GoogleAuthProvider.credential(credential.idToken, credential.accessToken)
  )
  return user
}
