export const formatLevel = (level: number) => {
  switch (level) {
    case 1:
      return 'الأول'
    case 2:
      return 'الثاني'
    case 3:
      return 'الثالث'
    case 4:
      return 'الرابع'
    case 5:
      return 'الخامس'
    case 6:
      return 'السادس'
    case 7:
      return 'السابع'
    case 8:
      return 'الثامن'
    case 9:
      return 'التاسع'
    default:
      return 'العاشر'
  }
}

/**
 * The function `mapClassLevel` takes a number representing a school level and returns the
 * corresponding Arabic term for that level, categorizing it as 'ابتدائي' (elementary), 'متوسط'
 * (intermediate), or 'ثانوي' (secondary).
 * @param {number} level - The function `mapClassLevel` takes a parameter `level` which is a number
 * representing the class level. The function then maps this class level to a corresponding educational
 * stage in Arabic language.
 * @returns The function `mapClassLevel` returns a string indicating the educational level based on the
 * input `level` parameter. The possible return values are:
 * - 'ابتدائي' (elementary) if the `level` is between 1 and 6 (inclusive).
 * - 'متوسط' (intermediate) if the `level` is between 7 and
 */
export const mapClassLevel = (level: number) => {
  if (level >= 1 && level <= 6) {
    return 'ابتدائي'
  } else if (level >= 7 && level <= 9) {
    return 'متوسط'
  } else {
    return 'ثانوي'
  }
}
export const fNumberOfPages = (numberOfPages: number) => {
  if (numberOfPages === 1) {
    return 'صفحة واحدة'
  }
  if (numberOfPages === 2) {
    return 'صفحتان'
  }
  if (numberOfPages <= 10) {
    return `${numberOfPages} صفحات`
  }
  return `${numberOfPages} صفحة`
}

export const fTime = (time: number) => {
  const seconds = Math.floor(time / 1000)
  if (seconds < 60) {
    return fNumberToArabic(seconds, 'ثانية واحدة', 'ثانيتان', 'ثوان', 'ثانية')
  }
  const minutes = Math.floor(time / 60000)
  if (minutes < 60) {
    return fNumberToArabic(minutes, 'دقيقة واحدة', 'دقيقتان', 'دقائق', 'دقيقة')
  }
  const hours = Math.floor(minutes / 60)
  return `${hours} ساعة`
}

export function fNumberToArabic(
  number: number,
  singular: string,
  double: string,
  below10: string,
  plural: string
): string {
  if (number === 1) return singular
  if (number === 2) return double
  if (number <= 10) return `${number} ${below10}`
  else return `${number} ${plural}`
}
