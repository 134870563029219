import { FC, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  useIonRouter,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButton
} from '@ionic/react'
import { BookOfWeek, HighlightedBooks, FavoriteBooks, FreeBooks, SignInModal } from '../../components'
import { personCircleOutline } from 'ionicons/icons'
//
import useAuth from '../../hooks/useAuth'
import { Auth } from '../../firebase/config'

const Library: FC = () => {
  const { user } = useAuth()

  const router = useIonRouter()

  const { currentUser } = Auth

  const [open, setOpen] = useState<boolean>(false)

  const handleClickProfile = () => {
    if (currentUser?.isAnonymous) {
      console.log('User is anonymous')
      setOpen(true)
      return
    }
    router.push('/profile')
  }

  if (!user) {
    return <Redirect to="/login" />
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonImg src="/assets/full_logo.svg" alt="stories app logo" className="w-[100px] h-[30px]" />
          <IonButtons slot="end">
            {/* <SignOutButton /> */}
            <IonButton
              fill="clear"
              onClick={handleClickProfile}
              // routerLink="/profile"
            >
              <IonIcon icon={personCircleOutline} size="large" className="navy" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        {/* <SubscriptionCTA /> */}
        <BookOfWeek />
        <FreeBooks />
        <HighlightedBooks />
        <FavoriteBooks />
        <SignInModal
          isOpen={open}
          message="تريد إنشاء حساب والاشتراك لتتمكن من قراءة القصص وإضافتها إلى مكتبتك؟"
          onDismiss={() => {
            console.log('Dismissed')
            setOpen(false)
          }}
        />
      </IonContent>
    </IonPage>
  )
}

export default Library
