import { FC, useState } from 'react'
import { pencil, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, useIonToast, IonSpinner, IonButtons } from '@ionic/react'
// hooks
import useAuth from '../../hooks/useAuth'
import { useEditUserData } from '../../hooks/useUser'

const Name: FC = () => {
  const { user } = useAuth()
  const [present] = useIonToast()

  const { mutate: editName, isLoading, isSuccess } = useEditUserData(user.uid)

  const [editMode, setEditMode] = useState(false)
  const [newName, setNewName] = useState<string>('')
  const [successMsg, setSuccessMsg] = useState<string>('')

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
    setNewName(user?.name)
  }

  const handleSave = () => {
    if (newName === '' || newName === user?.name) {
      setNewName(user?.name)
      setEditMode(false)
      return
    }
    editName({ name: newName })
    setEditMode(false)
  }

  if (isSuccess) {
    if (successMsg === '') {
      present({
        message: 'تم تعديل الاسم بنجاح',
        duration: 2000,
        color: 'success',
        position: 'bottom'
      })
      setSuccessMsg('done')
    }
  }

  return editMode ? (
    <IonItem
      className="mx-auto my-4"
      style={{
        '--background': 'transparent'
      }}
    >
      <IonInput
        value={newName}
        aria-label="اسم الطفل"
        placeholder={user.name || 'اسم الطفل'}
        type="text"
        autocomplete="on"
        fill="outline"
        clearInput
        onInput={(e: any) => setNewName(e.target.value)}
        onIonFocus={(e) => (e.target.placeholder = '')}
        onIonBlur={(e) => (e.target.placeholder = user.name || 'اسم الطفل')}
        className="foucs:outline-none text-black focus:ring-2 focus:ring-tertiary bg-light-main border-none"
      />
      <IonButtons slot="end">
        <IonButton fill="clear" onClick={handleCancel} color={'secondary'}>
          <IonIcon icon={closeCircleOutline} color={'secondary'} className="w-8 h-8" />
        </IonButton>
        <IonButton onClick={() => handleSave()} fill="clear" color={'secondary'} slot="end">
          {isLoading ? (
            <IonSpinner color="secondary" />
          ) : (
            <IonIcon icon={checkmarkCircleOutline} color={'secondary'} className="w-8 h-8" slot="start" />
          )}
        </IonButton>
      </IonButtons>
    </IonItem>
  ) : (
    <IonItem
      className="mx-auto my-4 w-min"
      style={{
        '--background': 'transparent'
      }}
    >
      <IonLabel>{user.name || 'اسم الطفل'}</IonLabel>
      <IonButton fill="clear" onClick={handleEdit} color={'secondary'}>
        <IonIcon icon={pencil} color={'secondary'} className="w-6 h-6" />
      </IonButton>
    </IonItem>
  )
}

export default Name
