import { FC } from 'react'
import { IonButton } from '@ionic/react'
// hooks
import useAuth from '../../hooks/useAuth'
import { useUserAddBook } from '../../hooks/useUserQueries'
import { BookWithStatistics } from '../../types/book'

interface AddButtonProps {
  book: BookWithStatistics
}

const AddButton: FC<AddButtonProps> = ({ book }) => {
  const { user } = useAuth()
  const { mutate: addBook, isLoading, isError, error } = useUserAddBook(user?.uid, book)
  console.log('useSearch book', book)
  if (isError || error) {
    console.log('error', isError, error)
    // presentToast({
    //   message: Errors[error as string] || error,
    //   duration: 3000,
    //   color: 'danger',
    //   cssClass: 'text-center'
    // })
  }

  return (
    <IonButton disabled={isLoading} onClick={() => addBook()} expand="block">
      {isLoading ? 'جاري الإضافة...' : 'أضف إلى مكتبتي'}
    </IonButton>
  )
}

export default AddButton
