import { FC, useEffect, useState } from 'react'
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { Thumbnail, AddButton, ReadButton, DownloadButton, SubscribeButton } from '../../components'
// hooks
import useAuth from '../../hooks/useAuth'
import { useGetBook } from '../../hooks/useLibrary'
import { useGetUsersBooks } from '../../hooks/useUserQueries'
import { useGetUserData } from '../../hooks/useUser'
// types
import { BookWithStatistics } from '../../types/book'
// utils
import { getDownloadUrl } from '../../utils/getDownloadUrl'
import { formatLevel, fNumberOfPages } from '../../utils/format'
import { checkFolderExistence } from '../../utils/checkFolderExistence'
//
import useModalState from '../../hooks/useState'

const Details: FC = () => {
  const { user } = useAuth()

  const { state, dispatch } = useModalState()

  const { data: userData } = useGetUserData(user.uid)
  const { data: userBooks, isLoading } = useGetUsersBooks(user.uid)
  const book = useGetBook(state.bid) as BookWithStatistics

  const [downlaoded, setDownloaded] = useState(false)
  const [inLibrary, setInLibrary] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkFolder = async () => {
      setLoading(true)
      const folderExists = await checkFolderExistence(state.bid)
      setLoading(false)
      setDownloaded(folderExists)
    }
    checkFolder()

    const checkInLibrary = () => {
      if (!userBooks) return
      const isInLibrary = userBooks.find((b) => b.folderName === state.bid)

      setInLibrary(!!isInLibrary)
    }
    checkInLibrary()
  }, [userBooks, state.bid])

  const handleClosing = () => {
    dispatch({
      type: 'HIDE',
      payload: ''
    })
  }

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      handleClosing()
    })
  })

  interface Props {
    uid: string
    downloadUrl: string
  }
  const RenderButton = ({ downloadUrl, uid }: Props) => {
    if (loading || isLoading) {
      return (
        <IonButton>
          <IonSpinner name="dots" />
        </IonButton>
      )
    }
    console.log('userData ', userData)
    console.log('userData?.subscription, book?.free ', userData?.subscription, book?.free)
    if (book?.free || inLibrary || (userData?.subscription !== undefined && userData?.subscription.isSubscribed)) {
      if (!inLibrary) {
        return <AddButton book={book} />
      } else {
        if (downlaoded) {
          return <ReadButton bid={state.bid} page={1} readCount={0} onClick={handleClosing} />
        } else {
          return <DownloadButton bid={state.bid} downloadUrl={downloadUrl} uid={uid} />
        }
      }
    }
    return <SubscribeButton onClick={handleClosing} />
  }

  return (
    book && (
      <IonModal isOpen={state.show} onDidDismiss={handleClosing}>
        <IonHeader translucent className="ion-no-border">
          <IonToolbar color={'primary'} className="rounded-b-3xl">
            <IonTitle>حول القصة</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleClosing}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div className="flex flex-col items-center my-4">
            <Thumbnail
              isCover
              withShadow
              bid={book.folderName}
              downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
            />
          </div>
          <IonCard className="rounded-xl shadow-xl container mx-auto">
            <IonCardHeader>
              <div className="mx-auto text-center">
                {RenderButton({
                  downloadUrl: getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`),
                  uid: user.uid
                })}
                {/* <RenderButton
                  downloadUrl={getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`)}
                  uid={user.uid}
                /> */}
              </div>
              <h1 className="text-2xl text-black text-center ion-text-wrap ion-padding font-29LTKaff-Medium leading-10">
                {book?.title}
              </h1>
            </IonCardHeader>
            <IonCardContent>
              <IonList>
                <IonItem>
                  <h2 className="font-bold">المستوى:&nbsp;</h2>
                  <span className="text-tertiary-main">{formatLevel(book?.level)}</span>
                </IonItem>
                <IonItem>
                  <h2 className="font-bold">عدد الصفحات:&nbsp;</h2>
                  <span className=" text-tertiary-main">{fNumberOfPages(book?.pages)}</span>
                </IonItem>
                <IonItem>
                  <h2 className="font-bold">عدد الكلمات:&nbsp;</h2>
                  <span className=" text-tertiary-main">{book?.words}</span>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    <h2 className="font-bold">حول القصة</h2>
                    <p className="font-29LTKaff">
                      <span className="text-lg" style={{ lineHeight: '40px' }}>
                        {book?.description}
                      </span>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>القيم المستفادة</h2>
                    {book?.keywords.map((keyword, index) => (
                      <p key={index} className="font-29LTKaff">
                        <span className="text-lg">
                          {keyword}
                          {index !== book.keywords.length - 1 && ', '}
                        </span>
                      </p>
                    ))}
                  </IonLabel>
                </IonItem>
                {/* <IonItem>
                  <IonLabel>
                    <h2>الناشر</h2>
                    {book?.publisher.map((r, index) => (
                      <span key={index} className="text-tertiary-main inline text-lg">
                        {r}
                        {index !== book.publisher.length - 1 && ', '}
                      </span>
                    ))}
                  </IonLabel>
                </IonItem> */}
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    )
  )
}

export default Details
