import { FC } from 'react'
import { IonButton, IonIcon, IonCard, IonCardContent, IonThumbnail, IonSkeletonText, useIonToast } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
// components
import { Thumbnail } from '../../components'
// hooks
import { useGetBookOfTheWeek } from '../../hooks/useLibrary'
import useModalState from '../../hooks/useState'
import useAuth from '../../hooks/useAuth'

const BookOfWeek: FC = () => {
  const { user } = useAuth()
  const { data: bookOfTheWeek, isLoading: bookOfWeekLoading, error: bookOfWeekError } = useGetBookOfTheWeek(user.uid)

  const { dispatch } = useModalState()

  const [presentToast] = useIonToast()

  if (bookOfWeekError) {
    console.log('error', bookOfWeekError)
    presentToast({
      message: bookOfWeekError as string,
      duration: 3000,
      color: 'danger',
      cssClass: 'text-center'
    })
  }

  return bookOfWeekLoading ? (
    <IonCard
      color={'inherit'}
      style={{ backgroundColor: '#8cc13a' }}
      className="max-w-md p-4 flex flex-row mx-auto justify-between rounded-xl items-center"
    >
      <IonThumbnail className="h-[140px] sm:h-[210px] w-[100px] sm:w-[135px]">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonCardContent className="w-3/4 space-y-4">
        <IonSkeletonText animated className="w-3/5 mx-auto p-2" />
        <h3>
          <IonSkeletonText animated className="w-1/2" />
        </h3>
        <p>
          <IonSkeletonText animated className="w-3/4" />
        </p>
        {Array.from({ length: 2 }).map((_, i) => (
          <IonSkeletonText key={i} animated className="w-full" />
        ))}
        <IonSkeletonText animated className="w-1/3 mx-auto p-4" />
      </IonCardContent>
    </IonCard>
  ) : (
    <IonCard
      color={'inherit'}
      style={{ backgroundColor: '#8cc13a' }}
      className="max-w-md p-2 sm:p-4 flex flex-row mx-auto justify-between rounded-xl items-center"
    >
      <Thumbnail
        withShadow
        bid={bookOfTheWeek?.folderName as string}
        downloadUrl={`stories/Level${bookOfTheWeek?.level}/${bookOfTheWeek?.folderName}/cover.jpg`}
      />
      <IonCardContent className="xw-3/4">
        <h1 className="text-center text-[#ffffff] font-bold">قِصَة الأسبوع</h1>
        <div className="mx-auto text-center my-4 space-y-3">
          <h2 className="text-lg line-clamp-1" style={{ color: '#371945' }}>
            {bookOfTheWeek?.title}
          </h2>
          <p className="line-clamp-2 font-29LTKaff" style={{ color: '#ffffff' }}>
            {bookOfTheWeek?.description}
          </p>
          <IonButton
            color={'inherit'}
            style={{ backgroundColor: '#ffffff', color: '#371945', borderRadius: '5px' }}
            onClick={() => dispatch({ type: 'SHOW', payload: bookOfTheWeek?.folderName })}
          >
            اقــرأ الآن
            <IonIcon icon={chevronBack} className="text-primary-main" style={{ color: '#371945' }} />
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default BookOfWeek
