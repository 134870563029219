import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Preferences } from '@capacitor/preferences'
import { updateUserBook, userDeleteBook } from '../../api'

const fileExists = async (path: string): Promise<boolean> => {
    try {
      await Filesystem.stat({
        path: path,
        directory: Directory.Data
      })
      return true
    } catch (e) {
      console.log('checkFileExists:', path, false, e)
      return false
    }
}

export const saveDataToCache = async (data: string, fileName: string) => {
  console.log('saveDataToCache:', fileName)
  if (fileName !== 'cachedUpdates.json')
    checkForCachedUpdates('cachedUpdates.json')
  try {
    await Filesystem.writeFile({
        path: fileName,
        data: data,
        encoding: Encoding.UTF8,
        directory: Directory.Data
    })
  } catch (error) {
      console.log('error in saveDataToCache:', error)
  }
}

export const getCachedData = async (fileName: string) => {
  if (await fileExists(fileName)) {
    try {
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
      console.log('getCachedData: ', fileName, JSON.stringify(contents))
      return JSON.parse(contents.data)
    } catch (error) {
      console.error('getCachedData.Error', error)
      return {}
    }
  } else {
    return {}
  }
}

export const checkForCachedUpdates = async (fileName: string) => {
  console.log('checkForCachedUpdates 1:', fileName)
  if ((await Preferences.keys()).keys.includes('HasCachedData')) {    
    console.log('checkForCachedUpdates:', 'HasCachedData')
    Preferences.remove({ key: 'HasCachedData' })
    const cachedData = await getCachedData(fileName)
    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data
    }).catch((error) => {
      console.error("Deletion error: ", error)
    });
    if (cachedData) {
      console.log('checkForCachedUpdates, cachedData:', cachedData)
      const uids = cachedData["uids"] || []      
      for (const uid in uids) {
        const userObj = uids[uid] || {}
        for (const bid in userObj) {
          const bidObj = userObj[bid] || {}
          await updateUserBook(uid, bid, bidObj)
        }
      }
      const deletedBooks = cachedData["deletedBooks"] || []
      for (let i = 0; i < deletedBooks.length; i++) {
        const book = deletedBooks[i]
        const uid = book["uid"]
        const bid = book["bid"]
        await userDeleteBook(uid, bid)        
      }
    }    
  }
}
  
export const saveUpdatesToCache = async (uid: string, bid: string, data: any) => {
  Preferences.set({ key: 'HasCachedData', value: 'true' })
  const fileName = 'cachedUpdates.json'
  const newUpdates = data
  const cachedData = await getCachedData(fileName)
  console.log("updates", cachedData)
  if (cachedData) {
    const uids = cachedData["uids"] || {}
    const userObj = uids[uid] || {}
    const bidObj = userObj[bid] || {}
    for (const p in newUpdates) {
      bidObj[p] = newUpdates[p]
    }
    userObj[bid] = bidObj
    uids[uid] = userObj
    cachedData["uids"] = uids
    console.log('saveUpdatesToCache:', cachedData)
  }
  await saveDataToCache(JSON.stringify(cachedData), fileName)
}

export const saveDeletedBooksToCache = async (uid: string, bid: string) => {
  Preferences.set({ key: 'HasCachedData', value: 'true' })
  const fileName = 'cachedUpdates.json'
  const cachedData = await getCachedData(fileName)
  console.log("updates", cachedData)
  if (cachedData) {
    const deletedBooks = cachedData["deletedBooks"] || []
    deletedBooks.push({ uid: uid, bid: bid })
    cachedData["deletedBooks"] = deletedBooks
    console.log('saveUpdatesToCache:', cachedData)
  }
  await saveDataToCache(JSON.stringify(cachedData), fileName)
}

