import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonInput, IonSpinner } from '@ionic/react'
import { twMerge } from 'tailwind-merge'
// api stuff and hooks
import useAuth from '../../hooks/useAuth'
import { useNewSubscription } from '../../services/subscription'

const schema = yup.object().shape({
  promocode: yup.string().required('يرجى إدخال رمز التفعيل')
})

type FormValues = {
  promocode: string
}

const PromoCodeForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })

  const { user } = useAuth()
  const { mutate, isLoading } = useNewSubscription(user?.uid)

  const onSubmit = (data: FormValues) => {
    try {
      mutate({ promoCode: data.promocode, UID: user?.uid })
    } catch (error) {
      console.log('Error: ', error)
    }
    reset()
  }

  return (
    <form className="flex flex-col mx-auto w-full" onSubmit={handleSubmit(onSubmit)}>
      <IonInput
        fill="outline"
        autocomplete="on"
        shape="round"
        color={'tertiary'}
        label=""
        placeholder="ادخل رمز التفعيل هنا"
        clearInput
        onIonFocus={(e) => (e.target.placeholder = '')}
        onIonBlur={(e) => (e.target.placeholder = 'ادخل رمز التفعيل هنا')}
        className={twMerge(
          'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
          errors.promocode && 'mb-0'
        )}
        {...register('promocode')}
      />
      {errors.promocode && <p className="text-red-500 text-right">{errors.promocode.message}</p>}
      <IonButton
        type="submit"
        expand="block"
        className="mt-4 font-bold"
        disabled={isSubmitting || isLoading}
        shape="round"
        size="large"
      >
        {isSubmitting || isLoading ? <IonSpinner name="lines-small" /> : 'تفعيل'}
      </IonButton>
    </form>
  )
}

export default PromoCodeForm
