import { FC, useEffect } from 'react'
import { nanoid } from 'nanoid'
// swiper
import SwiperCore, { Virtual, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// components
import { Thumbnail } from '../../components'
import { IonicSlides, IonSpinner, IonText } from '@ionic/react'
// hooks
import { useGetAllBooks } from '../../hooks/useSearch'
import useModalState from '../../hooks/useState'
// utils
import { formatLevel, mapClassLevel } from '../../utils/format'
import { getDownloadUrl } from '../../utils/getDownloadUrl'
// types
import { Book } from '../../types/book'

type BooksSection = {
  level: number
  books: Book[]
}

type ExploreProps = {
  show: boolean
}

const Explore: FC<ExploreProps> = ({ show }) => {
  const { dispatch } = useModalState()

  const { data: allBooks, isLoading: allBooksLoading, mutate: mutateBooks } = useGetAllBooks()

  useEffect(() => {
    if (!allBooks || allBooks.length === 0) mutateBooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (allBooksLoading)
    return (
      <div className="flex justify-center items-center h-full gap-x-4">
        <IonText className="text-2xl text-tertiary-main">يرجى الإنتظار</IonText>
        <IonSpinner color={'tertiary'} />
      </div>
    )

  SwiperCore.use([Virtual, Navigation, IonicSlides])

  const options = {
    slidesPerView: 10,
    spaceBetween: 10,
    grabCursor: true,
    // slidesOffsetBefore: 0,
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 30
      },
      1280: {
        slidesPerView: 8,
        spaceBetween: 20
      },
      1536: {
        slidesPerView: 9,
        spaceBetween: 20
      }
    }
  }

  return (
    <div className="mt-4">
      {show &&
        allBooks &&
        allBooks.map((booksSection: BooksSection) => (
          <div key={booksSection.level} className="mb-8">
            <h3 className="text-2xl mb-4 px-4">
              المستوى {formatLevel(booksSection.level)}{' '}
              <span className="text-tertiary-main font-bold ">({mapClassLevel(booksSection.level)})</span>
            </h3>
            <Swiper {...options} virtual navigation className="px-8">
              {booksSection.books.map((book: Book, idx) => (
                <SwiperSlide key={nanoid()} virtualIndex={idx} className="pb-8">
                  <Thumbnail
                    withShadow
                    bid={book.folderName}
                    downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                    onClick={() => {
                      dispatch({ type: 'SHOW', payload: book.folderName })
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))}
    </div>
  )
}

export default Explore
