import { Filesystem, Directory } from '@capacitor/filesystem'

export const checkFolderExistence = async (bid: string) => {
  try {
    await Filesystem.readdir({
      path: bid,
      directory: Directory.Data
    })
    return true
  } catch (error) {
    return false
  }
}
