import { createContext, ReactNode, useEffect, useReducer } from 'react'
import { Auth } from '../firebase/config'
import { signInAnonymously, linkWithCredential } from 'firebase/auth'
import { useGetUserData } from '../hooks/useUser'
import axios from '../api/axios'
import { registerUser } from '../api'
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
// @types
import { ActionMap, AuthState, AuthUser } from '../types/authentication'
import { Loader } from '../components'
// ----------------------------------------------------------------------

// interface AuthContextType {
//   isAuthenticated: boolean
//   isInitialized: boolean
//   isVerified: boolean
//   user: AuthUser | null
//   method: string
//   loading: boolean
//   error: any
//   login: (email: string, password: string) => Promise<any>
//   loginLoading: boolean
//   loginError: any
//   logout: () => Promise<void>
//   signInAnonymously: () => Promise<firebase.auth.UserCredential>
//    updateProfile: () => Promise<void>
// }

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    isVerified: boolean
    user: AuthUser
  }
}

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>]

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, isVerified } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isVerified,
      user
    }
  }
  return state
}

const AuthContext = createContext<any | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
  const [state] = useReducer(reducer, initialState)
  const [user, loading, error] = useAuthState(Auth)
  const [signInWithEmailAndPassword, , loginLoading, loginError] = useSignInWithEmailAndPassword(Auth)
  const { data, isLoading: loadingDB, isFetching } = useGetUserData(user?.uid)

  useEffect(() => {
    if (user?.uid && !loading) {
      user.getIdToken().then((token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        // log into portal db
        // if (user.email)
        registerUser(user.uid, user.email || null)
          .then((res) => {
            console.log('AuthCintext useEffect user saved to db')
          })
          .catch((err) => {
            console.error('AuthCintext useEffect user not saved to db', err)
          })
      })
    } else {
      delete axios.defaults.headers.common['Authorization']
    }
  }, [user, loading])

  const signInAnonymouslyUser = () => signInAnonymously(Auth)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const linkWithCredentialUser = async (credential: any) => await linkWithCredential(Auth.currentUser!, credential)

  const login = (email: string, password: string) => {
    const res = signInWithEmailAndPassword(email, password)
    console.log('login Error: ', loginError)
    return res
  }

  const logout = () => Auth.signOut()

  // if (getUserError) console.log('getUserError: ', getUserError)

  if (loading || (loadingDB && isFetching)) return <Loader />

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: user ? { ...user, ...data, email: user.email } : null,
        dbUser: data,
        loading: loading || (loadingDB && isFetching),
        // loadingDB: loadingDB && isFetching,
        error,
        login,
        loginLoading,
        loginError,
        logout,
        signInAnonymously: signInAnonymouslyUser,
        linkWithCredential: linkWithCredentialUser
        // updateProfile: () => {},
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
