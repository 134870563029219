import { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { QueryClient } from '@tanstack/react-query'
import { IonButton, IonSpinner, useIonAlert, useIonToast, IonText, IonList, IonItem, IonAlert } from '@ionic/react'
import { Auth } from '../../firebase/config'
import { deleteUser } from 'firebase/auth'
// hooks
import { useSignOut } from 'react-firebase-hooks/auth'
// api
import { deleteUserAccount } from '../../api'
import { Errors } from '../../firebase/error-codes'
import { Messages } from '../../api/server-messages'

interface Props {
  slot?: string
}

const DeleteAccountButton: FC<Props> = () => {
  const queryClient = new QueryClient()

  const [signOut] = useSignOut(Auth)
  const [presentAlert] = useIonAlert()
  const [presentToast] = useIonToast()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleDeleteAccount = () => {
    setLoading(true)
    if (Auth.currentUser)
      deleteUserAccount(Auth.currentUser.uid)
        .then((res) => {
          if (Auth.currentUser)
            deleteUser(Auth.currentUser)
              .then(() => {
                queryClient.clear()
                // queryClient.cancelQueries({ queryKey: ['user'] })
                // history.replace('/login')
                presentToast({
                  message: 'تم حذف الحساب بنجاح',
                  duration: 3000,
                  color: 'warning',
                  position: 'top'
                })
              })
              .catch((error) => {
                console.log('Error in Delete Account: ', error)
                if (error.message.includes('auth/requires-recent-login')) {
                  setIsOpen(true)
                } else showToast(error.code)
              })
          else {
            showToast('حدث خطأ أثناء حذف الحساب')
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log('Error in Delete Account: ', error)
          showToast(error)
        })
        .finally(() => setLoading(false))
    //         else {
    //           showToast('حدث خطأ أثناء حذف الحساب')
    //           setLoading(false)
    //         }
    //       })
    //       .catch((error) => {
    //         console.log('Error in Delete Account: ', error)
    //         showToast(error)
    //       })
    //       .finally(() => setLoading(false))
  }

  const showToast = (errMsg: string) => {
    presentToast({
      message: Messages[errMsg] || Errors[errMsg] || 'حدث خطأ أثناء حذف الحساب',
      duration: 3000,
      color: 'warning',
      position: 'top'
    })
  }

  const handleSignOut = async () => {
    await signOut()
    history.replace('/login')
  }

  return (
    <>
      <fieldset className="border-danger-main border-2 rounded-xl my-8 w-full">
        <legend
          className="text-danger-main text-right mr-4 px-1 text-xl font-bold"
          color="inherit"
          style={{ color: '#d8532b' }}
        >
          حذف الحساب
        </legend>
        <IonList className="rounded-xl" lines="full">
          <IonItem>
            <IonText>
              <p>
                عند حذف الحساب، سيتم حذف جميع البيانات المرتبطة بهذا الحساب بشكل دائم. ولا يمكن التراجع عن هذا الإجراء.
              </p>
            </IonText>
          </IonItem>
          <IonItem>
            <IonButton
              slot="end"
              onClick={() =>
                presentAlert({
                  header: 'حذف الحساب',
                  message: 'هل أنت متأكد من حذف الحساب؟',
                  buttons: [
                    { text: 'إلغاء', role: 'cancel', cssClass: 'alert-cancel-button' },
                    {
                      text: 'حذف',
                      handler: handleDeleteAccount,
                      cssClass: 'alert-delete-button'
                    }
                  ]
                })
              }
              color="light"
              className="font-bold text-md"
            >
              {loading ? <IonSpinner color="danger" /> : <IonText color="danger">إحذف حسابي</IonText>}
            </IonButton>
          </IonItem>
        </IonList>
      </fieldset>
      <IonAlert
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        header={'حذف الحساب'}
        message={'يجب إعادة تسجيل الدخول من جديد لحذف الحساب'}
        buttons={[
          {
            text: 'إلغاء',
            role: 'cancel',
            cssClass: 'alert-cancel-button'
          },
          {
            text: 'تسجيل الدخول',
            handler: () => handleSignOut(),
            cssClass: 'alert-info-button'
          }
        ]}
      />
    </>
  )
}

export default DeleteAccountButton
