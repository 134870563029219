import { IonImg, IonText } from '@ionic/react'

const Slide3: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-[#1b85b6]">
      <IonImg
        src="/assets/illustrations/intro/intro2.png"
        className="w-[250px] md:w-[300px] landscape:max-w-[100px] xl:landscape:max-w-[250px]"
      />
      <IonText className="text-center text-2xl font-bold block mt-8 w-3/4 sm:w-1/2 leading-10">
        قصص مختارة بعناية تربوية للقراء ما دون سن 15
      </IonText>
    </div>
  )
}

export default Slide3
