import { useContext, createContext, useState, ReactNode, useEffect } from 'react'
import { Network } from '@capacitor/network'
import { useAlert } from './AlertContext'

interface NetworkContextProps {
  connected: boolean
}

const NetworkContext = createContext<NetworkContextProps>({
  connected: true
})

export const NetworkProvider = ({ children }: { children: ReactNode }) => {
  const { setAlert } = useAlert()

  const [connected, setConnected] = useState<boolean>(true)

  Network.addListener('networkStatusChange', (status) => {
    setConnected(status.connected)
  })

  useEffect(() => {
    Network.getStatus().then((status) => {
      // console.log("status", status, status.connected)
      setConnected(status.connected)
      if (!status.connected) setAlert(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Network.getStatus().then((status) => console.log(" Network status", JSON.stringify(status), status.connected))

  return <NetworkContext.Provider value={{ connected }}>{children}</NetworkContext.Provider>
}

export const useNetwork = () => {
  const context = useContext(NetworkContext)
  if (context === undefined) {
    throw new Error('useNetwork must be used within a NetworkProvider')
  }
  return context
}

export default NetworkContext
