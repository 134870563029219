import { FC, useState } from 'react'
import { IonButton, useIonRouter } from '@ionic/react'
import { SignInModal } from '..'
// import { useHistory } from 'react-router'
import { Auth } from '../../firebase/config'
import { navigateToSubscribe } from '../../utils/subscriptionPath'
import { useNetwork } from '../../contexts/NetworkContext'

interface SubscribeButtonProps {
  onClick?: () => void
}

const SubscribeButton: FC<SubscribeButtonProps> = ({ onClick }) => {
  const { currentUser: user } = Auth
  const { connected } = useNetwork()

  const router = useIonRouter()

  const [isAnonymous, setAnonymous] = useState(false)

  const handleClick = () => {
    if (user?.isAnonymous) {
      console.log('User is anonymous')
      setAnonymous(true)
      return
    } else {
      router.push(navigateToSubscribe())
    }
    onClick && onClick()
  }

  return (
    <>
      <IonButton
        disabled={!connected}
        className="w-28 h-12 text-sm mx-auto"
        expand="block"
        // routerLink='/subscription'
        onClick={handleClick}
      >
        اشترك الآن
      </IonButton>
      <SignInModal
        isOpen={isAnonymous}
        message="لقراءة القصّة وإضافتها إلى مكتبتك، يجب عليك إنشاء حساب والاشتراك"
        onDismiss={() => {
          setAnonymous(false)
        }}
      />
    </>
  )
}

export default SubscribeButton
